import { ReactElement } from 'react';
import styles from './styles.module.sass';

export type chunksT = string | ReactElement;

export const white = (...chunks: chunksT[]) =>
  chunks.map((chunk) => <span className={styles.WhiteText}>{chunk}</span>);

export const gray = (...chunks: chunksT[]) =>
  chunks.map((chunk) => (
    <span className={styles.GrayText} key={`${chunk}-index`}>
      {chunk}
    </span>
  ));

export const boldText = (...chunks: chunksT[]) =>
  chunks.map((chunk) => <span className={styles.BoldText}>{chunk}</span>);

export const linebreak = <br />;

export const ul = (...chunks: chunksT[]) =>
  chunks.map((chunk) => <ul>{chunk}</ul>);

export const ol = (...chunks: chunksT[]) =>
  chunks.map((chunk) => <ol>{chunk}</ol>);

export const li = (...chunks: chunksT[]) =>
  chunks.map((chunk) => <li>{chunk}</li>);
