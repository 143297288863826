import { useEffect, useMemo, useState } from 'react';
import { SectionContainer } from '@rossum/rossum-ui/SectionContainer';
import { Button } from '@rossum/rossum-ui/Button';
import styles from './styles.module.sass';
import { FormattedMessage, useIntl } from 'react-intl';
import Headline from '../../components/Headline';
import Subtitle from '../../components/Subtitle';
import { Select } from '@rossum/rossum-ui/Select';
import MatchingLogicRules from './components/MatchingLogicRules';
import {
  ConfigMatchingRule,
  DataMatchingQueue,
  Dataset,
  DatasetOption,
  MatchingRule,
  MatchingSpecsResponse,
  QueueSelectOption,
  ResponseWithMessages,
} from '../../types';
import { makeRulesFromConfiguration, ParsedMatchingFlow, parseMatchingFlow } from './helpers';
import { merge } from '@rossum/rossum-ui/helpers';
import { compact, difference, identity, isEqual, uniqBy, values } from 'lodash';
import { AxiosError } from 'axios';
import sharedStyles from '../../lib/styles.module.sass';
import InnerSection from '../../components/InnerSection';
import {
  HeadlineLabel,
  Loader,
  Paragraph,
} from '../../components/utilityComponents';
import { useMutation, useQuery } from 'react-query';
import {
  createMatchingLogic,
  dataMatchingQueuesQueryKey,
  datasetsQueryKey,
  getDataMatchingQueues,
  getDatasets,
  getMatchingSpecs,
  matchingSpecsQuery,
  useHandleErrorHook,
} from '../../lib/api';
import { convertDatasetsToOptions } from '../../lib/helpers';
import FormErrorMessage from '../../components/Form/FormErrorMessage';
import { li, ol } from '../../lib/MessageValues';
import { CircularProgress } from '@mui/material';
import { useSetMessage } from '../../MessagesProvider';
import { useQueryClient } from 'react-query';
import { MatchingFlowTable } from './components/MatchingFlowTable';
import { DEV_FEATURES_ENABLED } from '../../config';

const excludeId = ({ id, ...rest }: MatchingRule) => rest;

const MatchingLogic = () => {
  const {
    data: datasetsData = [],
    error: datasetsError,
    isLoading: datasetsLoading,
  } = useQuery<
    { datasets: Dataset[] },
    AxiosError<ResponseWithMessages>,
    DatasetOption[]
  >(datasetsQueryKey, getDatasets, {
    select: convertDatasetsToOptions,
  });

  const {
    data: queuesData,
    error: queuesError,
    isLoading: queuesLoading,
  } = useQuery<
    { queues: DataMatchingQueue[] },
    AxiosError<ResponseWithMessages>
  >(dataMatchingQueuesQueryKey, getDataMatchingQueues);

  const queues = useMemo(
    () =>
      queuesData?.queues
        ? queuesData.queues
            .filter((queue) => !!queue.matching_config?.length)
            .map((queue) => ({
              ...queue,
              label: queue.name,
              value: String(queue.id),
            }))
        : [],
    [queuesData],
  );

  const [selectedQueue, setSelectedQueue] = useState<
    QueueSelectOption | undefined
  >(queues[0]);

  const {
    data: {
      limitations,
      matchable_fields: matchableFields,
      matching_techniques: matchingTechniques,
    } = {
      limitations: [],
      matchable_fields: [],
      matching_techniques: [],
    } as MatchingSpecsResponse,
  } = useQuery(
    [matchingSpecsQuery, selectedQueue?.id],
    getMatchingSpecs(selectedQueue?.id as number),
    { enabled: !!selectedQueue?.id },
  );

  const datasets = useMemo(
    () =>
      selectedQueue
        ? compact(
            selectedQueue.matching_config.map(({ dataset }) =>
              datasetsData.find(
                (datasetOption) => datasetOption.dataset === dataset,
              ),
            ),
          )
        : [],
    [selectedQueue, datasetsData],
  );
  const [selectedDataset, setSelectedDataset] = useState<
    DatasetOption | undefined
  >(datasets[0]);
  const [limitationValue, setLimitationValue] = useState(limitations[0]?.value);
  const [matchingRules, setMatchingRules] = useState<Array<MatchingRule>>([]);

  // TODO parse from backend and handle error
  const [matchingFlow, setMatchingFlow] = useState<ParsedMatchingFlow>(parseMatchingFlow('(1 AND 2 AND 5) OR (3) OR (4)'));

  const [successMessage, setSuccessMessage] = useState<string>('');

  const [thresholdErrors, setThresholdErrors] = useState<
    Record<number, boolean>
  >({});

  const masterDataKeys = useMemo(
    () => selectedDataset?.master_data_keys || [],
    [selectedDataset],
  );

  useEffect(() => {
    setSelectedQueue(
      selectedQueue?.value
        ? queues.find(({ value }) => value === selectedQueue.value)
        : queues[0],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queues]);

  useEffect(() => {
    if (
      !selectedDataset ||
      !datasets.some(
        (datasetOption) => datasetOption.dataset === selectedDataset?.dataset,
      )
    ) {
      setSelectedDataset(datasets[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasets]);

  useEffect(() => {
    if (!limitationValue) {
      setLimitationValue(limitations[0]?.value);
    }
  }, [limitations, limitationValue]);

  const queueDatasetRules = selectedQueue?.configuration?.matching_rules?.find(
    ({ dataset }) => dataset === selectedDataset?.dataset,
  );

  useEffect(() => {
    const rules = queueDatasetRules
      ? makeRulesFromConfiguration(queueDatasetRules.rules)
      : [];
    setMatchingRules(rules);

    if (queueDatasetRules) {
      setLimitationValue(queueDatasetRules.message_type);
    }

    setThresholdErrors({});
  }, [queueDatasetRules, selectedQueue]);

  useEffect(() => {
    if (successMessage) {
      setSuccessMessage('');
    }
  }, [selectedQueue?.value]); // eslint-disable-line react-hooks/exhaustive-deps

  const noChanges =
    isEqual(
      (queueDatasetRules
        ? makeRulesFromConfiguration(queueDatasetRules.rules)
        : []
      ).map(excludeId),
      matchingRules.map(excludeId),
    ) &&
    queueDatasetRules &&
    queueDatasetRules.message_type === limitationValue;

  const selectedMasterDataKeys = useMemo(
    () =>
      ([] as string[]).concat(
        ...matchingRules.map(({ masterDataKeys }) => [...masterDataKeys]),
      ),
    [matchingRules],
  );

  const possibleMasterDataValues = useMemo(
    () => masterDataKeys.map(({ value }) => value),
    [masterDataKeys],
  );

  const desyncedRules = useMemo(
    () => difference(selectedMasterDataKeys, possibleMasterDataValues),
    [selectedMasterDataKeys, possibleMasterDataValues],
  );

  const submitDisabled = !(
    selectedQueue &&
    selectedDataset &&
    limitationValue &&
    matchingRules.length &&
    values(thresholdErrors).filter(identity).length === 0 &&
    !desyncedRules.length &&
    !noChanges
  );

  const handleError = useHandleErrorHook();
  useEffect(() => {
    const error = queuesError || datasetsError;
    if (error) {
      handleError(error);
    }
  }, [handleError, datasetsError, queuesError]);

  const setMessage = useSetMessage();
  const intl = useIntl();
  const queryClient = useQueryClient();

  const mutation = useMutation(createMatchingLogic, {
    onError: (error: AxiosError<ResponseWithMessages>) => {
      handleError(error);
    },
    onSuccess: (request, variables) => {
      const {
        matching_rules: [updatedMatchingRules],
      } = JSON.parse(request.config.data);
      queryClient.setQueryData<{ queues: Array<DataMatchingQueue> }>(
        dataMatchingQueuesQueryKey,
        ({ queues } = { queues: [] }) => {
          const updatedQueueIndex = queues.findIndex(
            (queue) => variables.queue_id === queue.id,
          );

          if (updatedQueueIndex === -1) {
            return { queues };
          }

          const queueMatchingRules =
            queues[updatedQueueIndex]?.configuration?.matching_rules || [];
          const head = queues.slice(0, updatedQueueIndex);
          const tail = queues.slice(updatedQueueIndex + 1);

          return {
            queues: [
              ...head,
              {
                ...queues[updatedQueueIndex],
                configuration: {
                  matching_rules: uniqBy(
                    [
                      updatedMatchingRules as ConfigMatchingRule,
                      ...queueMatchingRules,
                    ],
                    'dataset',
                  ),
                },
              },
              ...tail,
            ],
          };
        },
      );
    },
    onSettled: (data, error: AxiosError<ResponseWithMessages> | null) => {
      const isSuccess = data?.data.success;
      setMessage({
        type: isSuccess ? 'info' : 'error',
        content: isSuccess
          ? intl.formatMessage({ id: 'tabs.logic.messages.success' })
          : error?.response?.data?.messages ??
            intl.formatMessage({ id: 'app.messages.error.unexpected' }),
      });
    },
  });
  const submitPending = mutation.isLoading;

  return (
    <>
      <Headline>
        <FormattedMessage id="tabs.logic.title" />
      </Headline>

      <SectionContainer
        title={<FormattedMessage id="tabs.logic.guide.title" />}
      >
        <InnerSection>
          <Paragraph>
            <FormattedMessage id="tabs.logic.guide.description_1" />
          </Paragraph>
          <div className={merge(sharedStyles.GrayText, styles.GuideList)}>
            <FormattedMessage
              id="tabs.logic.guide.description_2"
              values={{ li, ol }}
            />
          </div>
        </InnerSection>
      </SectionContainer>

      <SectionContainer
        title={<FormattedMessage id="tabs.logic.queue.title" />}
      >
        <InnerSection>
          <Subtitle>
            <FormattedMessage id="tabs.logic.queue.description" />
          </Subtitle>
          <div className={styles.SelectWrapper}>
            <div
              className={merge(
                styles.Select,
                (!queues.length || submitPending) && styles.SelectPending,
              )}
            >
              <Select
                isDisabled={!queues.length || submitPending}
                options={queues}
                title="queue-select"
                placeholder="— — —"
                value={selectedQueue?.value ?? ''}
                onChange={(queueId: string) => {
                  setSelectedQueue(
                    queues.find(({ value }) => value === queueId),
                  );
                }}
              />
            </div>
            {queuesLoading && <Loader />}
          </div>
          {!queues.length && !queuesLoading && (
            <div className={styles.ErrorMessage}>
              <FormErrorMessage>
                <FormattedMessage id="tabs.logic.noQueuesWithConfig" />
              </FormErrorMessage>
            </div>
          )}

          <HeadlineLabel>
            <FormattedMessage id="tabs.logic.datasetSelection.title" />
          </HeadlineLabel>

          <Subtitle>
            <FormattedMessage id="tabs.logic.datasetSelection.description" />
          </Subtitle>

          <div className={styles.SelectWrapper}>
            <div
              className={merge(
                styles.Select,
                (!datasets.length || submitPending) && styles.SelectPending,
              )}
            >
              <Select
                isDisabled={!datasets.length || submitPending}
                options={datasets}
                title="dataset-select"
                placeholder="— — —"
                value={selectedDataset?.dataset ?? ''}
                onChange={(value: string) => {
                  setSelectedDataset(
                    datasets.find((dataset) => dataset.value === value),
                  );
                }}
              />
            </div>
            {(queuesLoading || datasetsLoading) && <Loader />}
          </div>
          {!!queues.length &&
            !datasets.length &&
            !(queuesLoading || datasetsLoading) && (
              <div className={styles.ErrorMessage}>
                <FormErrorMessage>
                  <FormattedMessage id="tabs.logic.noAvailableDataset" />
                </FormErrorMessage>
              </div>
            )}
        </InnerSection>
      </SectionContainer>
      <SectionContainer
        title={<FormattedMessage id="tabs.logic.rules.title" />}
      >
        <Subtitle>
          <FormattedMessage id="tabs.logic.rules.description" />
        </Subtitle>

        <MatchingLogicRules
          submitPending={submitPending}
          matchableFields={matchableFields}
          masterDataKeys={masterDataKeys}
          matchingTechniques={matchingTechniques}
          matchingRules={matchingRules}
          setMatchingRules={setMatchingRules}
          thresholdErrorsState={[thresholdErrors, setThresholdErrors]}
          desyncedRules={desyncedRules}
        />
      </SectionContainer>

      {DEV_FEATURES_ENABLED &&
        <SectionContainer
          title={<FormattedMessage id="tabs.logic.conditions.title" />}
        >
          <Subtitle>
            <FormattedMessage id="tabs.logic.conditions.description" />
          </Subtitle>

          <MatchingFlowTable
            matchingFlow={matchingFlow}
            onChange={setMatchingFlow}
            availableRules={matchingRules} />

        </SectionContainer>}

      <SectionContainer
        title={<FormattedMessage id="tabs.logic.confirmLimitations.title" />}
      >
        <InnerSection>
          <Subtitle>
            <FormattedMessage id="tabs.logic.confirmLimitations.description" />
          </Subtitle>
          <div className={styles.SelectWrapper}>
            <div
              className={merge(
                styles.Select,
                (submitPending || !limitations.length) && styles.SelectPending,
              )}
            >
              <Select
                isDisabled={submitPending || !limitations.length}
                options={limitations}
                title="queue-select"
                value={limitationValue}
                placeholder="— — —"
                onChange={setLimitationValue}
              />
            </div>
          </div>
        </InnerSection>
      </SectionContainer>

      <Button
        size="large"
        className={sharedStyles.SubmitButton}
        disabled={submitDisabled || submitPending}
        onClick={() =>
          !submitDisabled &&
          mutation.mutate({
            queue_id: Number(selectedQueue.value),
            rules: matchingRules,
            limitation: limitationValue,
            dataset: selectedDataset.dataset,
          })
        }
      >
        {submitPending ? (
          <>
            <CircularProgress
              color="inherit"
              size={16}
              sx={{ marginRight: 1 }}
            />
            <FormattedMessage id="tabs.logic.submitPending" />
          </>
        ) : (
          <FormattedMessage id="tabs.logic.submitButton" />
        )}
      </Button>
    </>
  );
};

export default MatchingLogic;
