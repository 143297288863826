import { merge } from '@rossum/rossum-ui/helpers';
import { SectionContainer } from '@rossum/rossum-ui/SectionContainer';
import { Select } from '@rossum/rossum-ui/Select';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Headline from '../../components/Headline';
import Subtitle from '../../components/Subtitle';
import {
  DataMatchingQueue,
  Dataset,
  DatasetOption,
  MatchingFieldConfig,
  ReferenceRelationT,
  ResponseWithMessages,
  SelectOption,
} from '../../types';
import styles from './styles.module.sass';
import sharedMatchingStyles from '../MatchingLogic/styles.module.sass';
import sharedLibStyles from '../../lib/styles.module.sass';
import { FocusEvent } from 'react';
import { Input } from '@rossum/rossum-ui/Input';
import { snakeCase, uniqBy } from 'lodash';
import { Button } from '@rossum/rossum-ui/Button';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  datasetsQueryKey,
  getDatasets,
  dataMatchingQueuesQueryKey,
  useHandleErrorHook,
  createMatchingConfig,
} from '../../lib/api';
import { convertDatasetsToOptions } from '../../lib/helpers';
import InfoTooltip from '../../components/InfoTooltip';
import { Box, CircularProgress } from '@mui/material';
import InnerSection from '../../components/InnerSection';
import { AxiosError } from 'axios';
import { useSetMessage } from '../../MessagesProvider';
import { useQueueSchema, useQueuesQuery } from './hooks';
import FormErrorMessage from '../../components/Form/FormErrorMessage';
import {
  ChildrenProps,
  HeadlineLabel,
  Loader,
  Paragraph,
} from '../../components/utilityComponents';

const InfoText = ({ children }: ChildrenProps) => (
  <p className={styles.InfoText}>{children}</p>
);

const Label = ({ children }: ChildrenProps) => (
  <label className={styles.Label}>{children}</label>
);

const defaultReferenceRelation = 'before';

const SetupMatchingField = () => {
  const intl = useIntl();
  const {
    data: datasets = [],
    error: datasetsError,
    isLoading: datasetsLoading,
  } = useQuery<
    { datasets: Dataset[] },
    AxiosError<ResponseWithMessages>,
    DatasetOption[]
  >(datasetsQueryKey, getDatasets, {
    select: convertDatasetsToOptions,
  });

  const {
    queues,
    error: queuesError,
    isLoading: queuesLoading,
  } = useQueuesQuery();

  const queryClient = useQueryClient();
  const setMessage = useSetMessage();

  const mutation = useMutation(createMatchingConfig, {
    onError: (error: AxiosError<ResponseWithMessages>) => {
      handleError(error);
    },
    onSuccess: (_, { queue_id, ...matching_config }) => {
      const { queues } = queryClient.getQueryData<{
        queues: Array<DataMatchingQueue>;
      }>(dataMatchingQueuesQueryKey) || { queues: [] };

      if (queues.length === 0) {
        queryClient.invalidateQueries(dataMatchingQueuesQueryKey);
        return;
      }

      queryClient.setQueryData<{ queues: Array<DataMatchingQueue> }>(
        dataMatchingQueuesQueryKey,
        ({ queues } = { queues: [] }) => {
          const updatedQueueIndex = queues.findIndex(
            (queue) => queue_id === queue.id,
          );

          if (updatedQueueIndex === -1) {
            return { queues };
          }

          const queueMatchingConfigs =
            queues[updatedQueueIndex]?.matching_config || [];
          const head = queues.slice(0, updatedQueueIndex);
          const tail = queues.slice(updatedQueueIndex + 1);

          return {
            queues: [
              ...head,
              {
                ...queues[updatedQueueIndex],
                matching_config: uniqBy(
                  [
                    matching_config as MatchingFieldConfig,
                    ...queueMatchingConfigs,
                  ],
                  'dataset',
                ),
              },
              ...tail,
            ],
          };
        },
      );
    },
    onSettled: (data, error) =>
      setMessage({
        type: data ? 'info' : 'error',
        content: data
          ? intl.formatMessage({ id: 'tabs.setup.messages.success' })
          : error?.response?.data?.messages ??
            intl.formatMessage({ id: 'app.messages.error.unexpected' }),
      }),
  });

  const submitPending = mutation.isLoading;

  useEffect(() => {
    setSelectedQueue(
      selectedQueue?.value
        ? queues.find(({ value }) => value === selectedQueue.value)
        : queues[0],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queues]);

  const [selectedQueue, setSelectedQueue] = useState<
    | (SelectOption & {
        schema: string;
        matching_config: MatchingFieldConfig[] | null;
        id: number;
      })
    | undefined
  >(queues[0]);
  const [selectedDataset, setSelectedDataset] = useState<
    DatasetOption | undefined
  >(datasets[0]);
  const [fieldLabel, setFieldLabel] = useState<string>('');
  const [fieldId, setFieldId] = useState<string>('');
  const [referenceRelation, setReferenceRelation] =
    useState<ReferenceRelationT>(defaultReferenceRelation);
  const [referenceField, setReferenceField] = useState<string>('');
  const [extraIdentifier, setExtraIdentifier] = useState('');

  useEffect(() => {
    if (!selectedDataset) {
      setSelectedDataset(datasets[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datasets]);

  const fieldsDisabled = !(selectedDataset && selectedQueue) || submitPending;

  const configForDataset = useMemo(
    () =>
      selectedQueue?.matching_config?.find(
        ({ dataset }) => dataset === selectedDataset?.dataset,
      ),
    [selectedDataset, selectedQueue],
  );

  useEffect(() => {
    if (selectedDataset && selectedQueue) {
      setFieldId(configForDataset?.matching_enum ?? '');
      setFieldLabel(configForDataset?.matching_field_label ?? '');
      setExtraIdentifier(
        selectedDataset.master_data_keys.find(
          ({ value }) => value === configForDataset?.extra_identifier,
        )?.value || '',
      );
      setReferenceRelation(
        configForDataset?.reference_relation ?? defaultReferenceRelation,
      );
      setReferenceField(configForDataset?.reference_field ?? '');
    }
  }, [selectedDataset, selectedQueue, configForDataset]);

  const handleError = useHandleErrorHook();
  useEffect(() => {
    const error = queuesError || datasetsError;
    if (error) {
      handleError(error);
    }
  }, [handleError, datasetsError, queuesError]);

  const { schema: schemaOptions, status: schemaStatus } = useQueueSchema(
    selectedQueue?.schema,
  );

  const filteredSchemaOptions = useMemo(
    () =>
      schemaOptions && configForDataset?.matching_enum
        ? schemaOptions.filter(
            ({ value }) => value !== configForDataset.matching_enum,
          )
        : schemaOptions,
    [schemaOptions, configForDataset],
  );

  const submitDisabled =
    !(
      selectedQueue &&
      selectedDataset &&
      fieldId &&
      fieldLabel &&
      referenceField &&
      referenceRelation
    ) || submitPending;

  return (
    <>
      <div>
        <Headline>
          <FormattedMessage id="tabs.setup.title" />
        </Headline>

        <SectionContainer
          title={<FormattedMessage id="tabs.setup.guide.title" />}
        >
          <InnerSection>
            <Paragraph>
              <FormattedMessage id="tabs.setup.guide.description_1" />
            </Paragraph>
            <Paragraph>
              <FormattedMessage id="tabs.setup.guide.description_2" />
            </Paragraph>
            <Paragraph>
              <a
                className={styles.GuideLink}
                target="_blank"
                href="https://rossum.ai/help/article/automatically-match-data-to-existing-companies/"
                rel="noopener noreferrer"
              >
                <FormattedMessage id="tabs.setup.guide.link" />
              </a>
            </Paragraph>
          </InnerSection>
        </SectionContainer>

        <SectionContainer
          title={<FormattedMessage id="tabs.setup.queueSelection.title" />}
        >
          <InnerSection>
            <Subtitle>
              <FormattedMessage id="tabs.setup.queueSelection.description" />
            </Subtitle>

            <div className={sharedMatchingStyles.SelectWrapper}>
              <div
                className={merge(
                  sharedMatchingStyles.Select,
                  (!queues.length || submitPending) &&
                    sharedMatchingStyles.SelectPending,
                )}
              >
                <Select
                  isDisabled={!queues.length || submitPending}
                  options={queues}
                  title="queue-select"
                  placeholder="— — —"
                  value={selectedQueue?.value ?? ''}
                  onChange={(queueId: string) => {
                    setSelectedQueue(
                      queues.find(({ value }) => value === queueId),
                    );
                  }}
                />
              </div>
              {queuesLoading && <Loader />}
            </div>

            <HeadlineLabel>
              <FormattedMessage id="tabs.setup.datasetSelection.title" />
            </HeadlineLabel>

            <Subtitle>
              <FormattedMessage id="tabs.setup.datasetSelection.description" />
            </Subtitle>

            <div className={sharedMatchingStyles.SelectWrapper}>
              <div
                className={merge(
                  sharedMatchingStyles.Select,
                  (!datasets.length || submitPending) &&
                    sharedMatchingStyles.SelectPending,
                )}
              >
                <Select
                  isDisabled={!datasets.length || submitPending}
                  options={datasets}
                  title="dataset-select"
                  placeholder="— — —"
                  value={selectedDataset?.dataset ?? ''}
                  onChange={(value: string) => {
                    setSelectedDataset(
                      datasets.find((dataset) => dataset.value === value),
                    );
                  }}
                />
              </div>
              {datasetsLoading && <Loader />}
            </div>
            {!datasets.length && !datasetsLoading && (
              <div className={sharedMatchingStyles.ErrorMessage}>
                <FormErrorMessage>
                  <FormattedMessage id="tabs.setup.noDatasets" />
                </FormErrorMessage>
              </div>
            )}
          </InnerSection>
        </SectionContainer>

        <SectionContainer
          title={<FormattedMessage id="tabs.setup.matchingField.title" />}
        >
          <InnerSection>
            <div className={styles.SimpleLabel}>
              <FormattedMessage id="tabs.setup.matchingField.fieldDef.title" />
            </div>
            <InfoText>
              <FormattedMessage id="tabs.setup.matchingField.fieldDef.description" />
            </InfoText>
            <div className={styles.FieldGrid}>
              <div>
                <Label>
                  <FormattedMessage id="tabs.setup.matchingField.fieldDef.label" />
                </Label>
                <Input
                  disabled={submitPending}
                  onChange={setFieldLabel}
                  value={fieldLabel}
                  placeholder={intl.formatMessage({
                    id: 'tabs.setup.matchingField.fieldDef.placeholder.label',
                  })}
                  onBlur={({
                    target: { value },
                  }: FocusEvent<HTMLInputElement>) => {
                    if (!fieldId) {
                      setFieldId(snakeCase(value));
                    }
                  }}
                />
              </div>
              <div>
                <Label>
                  <FormattedMessage id="tabs.setup.matchingField.fieldDef.schemaId" />
                </Label>
                <Input
                  disabled={submitPending || !!configForDataset?.matching_enum}
                  onChange={setFieldId}
                  value={fieldId}
                  placeholder={intl.formatMessage({
                    id: 'tabs.setup.matchingField.fieldDef.placeholder.id',
                  })}
                />
              </div>
            </div>

            <Box marginTop={3}>
              <InfoText>
                <FormattedMessage id="tabs.setup.matchingField.fieldDef.placementDescription" />
              </InfoText>
              <div className={styles.FieldGrid}>
                <div>
                  <Label>
                    <FormattedMessage id="tabs.setup.matchingField.position.label" />
                  </Label>
                  <div
                    className={merge(
                      sharedMatchingStyles.Select,
                      fieldsDisabled && sharedMatchingStyles.SelectPending,
                    )}
                  >
                    <Select
                      isDisabled={fieldsDisabled}
                      options={[
                        {
                          value: 'before',
                          label: intl.formatMessage({
                            id: 'tabs.setup.matchingField.position.before',
                          }),
                        },
                        {
                          value: 'after',
                          label: intl.formatMessage({
                            id: 'tabs.setup.matchingField.position.after',
                          }),
                        },
                      ]}
                      title="field-position"
                      placeholder={intl.formatMessage({
                        id: 'tabs.setup.matchingField.position.placeholder',
                      })}
                      value={referenceRelation}
                      onChange={setReferenceRelation}
                    />
                  </div>
                </div>
                <div>
                  <Label>
                    <FormattedMessage id="tabs.setup.matchingField.referenceField.label" />
                  </Label>
                  <div className={sharedMatchingStyles.SelectWrapper}>
                    <div
                      className={merge(
                        sharedMatchingStyles.Select,
                        fieldsDisabled && sharedMatchingStyles.SelectPending,
                      )}
                    >
                      <Select
                        key={referenceField}
                        isDisabled={
                          fieldsDisabled || schemaStatus === 'loading'
                        }
                        options={filteredSchemaOptions || []}
                        title="reference-field"
                        placeholder={intl.formatMessage({
                          id: 'tabs.setup.matchingField.referenceField.placeholder',
                        })}
                        value={referenceField}
                        onChange={setReferenceField}
                      />
                      {schemaStatus === 'error' && (
                        <FormErrorMessage>
                          <FormattedMessage id="tabs.setup.matchingField.referencefield.error" />
                        </FormErrorMessage>
                      )}
                    </div>
                    <Box sx={{ width: 0 }}>
                      {schemaStatus === 'loading' && <Loader />}
                    </Box>
                  </div>
                </div>
              </div>
            </Box>

            <HeadlineLabel>
              <FormattedMessage id="tabs.setup.matchingField.additionalInfo.title" />
            </HeadlineLabel>
            <InfoText>
              <FormattedMessage id="tabs.setup.matchingField.additionalInfo.description" />
            </InfoText>
            <div className={styles.FieldGrid}>
              <div>
                <Label>
                  <FormattedMessage id="tabs.setup.matchingField.uniqueIdentifier.label" />
                  <InfoTooltip translationId="tabs.setup.matchingField.uniqueIdentifier.tooltip" />
                </Label>
                <Input
                  value={selectedDataset?.unique_identifier ?? ''}
                  disabled
                />
              </div>
              <div>
                <Label>
                  <FormattedMessage id="tabs.setup.matchingField.extraInfo.label" />
                  <InfoTooltip translationId="tabs.setup.matchingField.extraInfo.tooltip" />
                </Label>
                <div className={sharedMatchingStyles.SelectWrapper}>
                  <div
                    className={merge(
                      sharedMatchingStyles.Select,
                      fieldsDisabled && sharedMatchingStyles.SelectPending,
                    )}
                  >
                    <Select
                      isDisabled={fieldsDisabled}
                      options={[
                        {
                          value: '',
                          label: intl.formatMessage({
                            id: 'tabs.setup.setExtraIdentifier.default',
                          }),
                        },
                        ...(selectedDataset?.master_data_keys || []),
                      ]}
                      title="extra-identifier"
                      placeholder={intl.formatMessage({
                        id: 'tabs.setup.setExtraIdentifier.placeholder',
                      })}
                      value={extraIdentifier}
                      onChange={setExtraIdentifier}
                    />
                  </div>
                </div>
              </div>
            </div>
          </InnerSection>
        </SectionContainer>
      </div>
      <Button
        size="large"
        className={sharedLibStyles.SubmitButton}
        disabled={submitDisabled}
        onClick={() =>
          !submitDisabled &&
          mutation.mutate({
            queue_id: selectedQueue.id,
            dataset: selectedDataset.dataset,
            matching_enum: fieldId,
            matching_field_label: fieldLabel,
            extra_identifier: extraIdentifier || null,
            reference_relation: referenceRelation,
            reference_field: referenceField,
          })
        }
      >
        {submitPending ? (
          <>
            <CircularProgress
              color="inherit"
              size={16}
              sx={{ marginRight: 1 }}
            />
            <FormattedMessage id="tabs.setup.submitPending" />
          </>
        ) : (
          <FormattedMessage id="tabs.setup.submitButton" />
        )}
      </Button>
    </>
  );
};

export default SetupMatchingField;
