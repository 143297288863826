import { ReactNode } from 'react';
import styles from './styles.module.sass';

type Props = {
  children: ReactNode;
};

const FieldWrapper = ({ children }: Props) => (
  <div className={styles.FieldWrapper}>{children}</div>
);

export default FieldWrapper;
