import styles from './styles.module.sass';
import { FormattedMessage } from 'react-intl';

const PromoContainer = () => (
  <div className={styles.PromoContainer}>
    <a
      href="https://rossum.ai/help/article/automatically-match-data-to-existing-companies/"
      rel="noopener noreferrer"
      target="_blank"
      className={styles.Link}
    >
      <FormattedMessage id="components.promoContainer.helpCenterLink" />
    </a>
  </div>
);

export default PromoContainer;
