import { ReactNode, useContext } from 'react';
import { Message, MessageHolder } from '@rossum/rossum-ui/Message';
import { messagesContext, messageTimeout, useProvideMessages } from './hooks';
import { useIntl } from 'react-intl';
import { uniqueId } from 'lodash';
import styles from './styles.module.sass';

type MessagesProviderProps = { children: ReactNode };

export const MessagesProvider = ({ children }: MessagesProviderProps) => {
  const msg = useProvideMessages();
  const intl = useIntl();

  return (
    <messagesContext.Provider value={msg}>
      {!!msg.message && (
        <MessageHolder key={`message-${uniqueId()}`}>
          <Message
            remove={() => msg.setMessage(null)}
            title={intl.formatMessage({
              id: `app.messages.${msg.message.type}.title`,
            })}
            description={
              typeof msg.message.content === 'string'
                ? msg.message.content
                : msg.message.content.map((messageContent: string) => (
                    <div
                      className={styles.MultipleMessages}
                      key={messageContent}
                    >
                      {messageContent}
                    </div>
                  ))
            }
            type={msg.message.type}
            timeout={messageTimeout}
            id="data-matching-message"
          />
        </MessageHolder>
      )}
      {children}
    </messagesContext.Provider>
  );
};

export const useSetMessage = () => useContext(messagesContext).setMessage;
