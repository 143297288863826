import { Select } from "@rossum/rossum-ui/Select";
import { Button } from '@rossum/rossum-ui/Button';
import { merge } from '@rossum/rossum-ui/helpers';
import PlusIcon from "mdi-react/PlusIcon";
import TrashIcon from "mdi-react/TrashIcon";
import { FormattedMessage } from "react-intl";
import { MatchingRule } from "../../../types";
import * as MF from '../helpers';
import { ParsedMatchingFlow, MatchingFlowOperator } from "../helpers";
import styles from '../styles.module.sass';
import React from "react";

export type MatchingFlowTableProps = {
  matchingFlow: ParsedMatchingFlow;
  onChange: (value: ParsedMatchingFlow) => void;
  availableRules: MatchingRule[];
};

export const MatchingFlowTable = ({ matchingFlow, onChange, availableRules } : MatchingFlowTableProps) =>
  <>
    <div className={styles.MatchingFlowTable}>
      {matchingFlow.map(({nextOperator}, rowIndex) =>
        <React.Fragment key={rowIndex}>
          <MatchingFlowTableRow
            matchingFlow={matchingFlow}
            rowIndex={rowIndex}
            availableRules={availableRules}
            onChange={onChange} />
          {nextOperator &&
            <div className={styles.MatchingFlowTableRow}>
              <OperatorSelect
                operator={nextOperator}
                name={`operator-${rowIndex}`}
                onChange={value => onChange(MF.setNextOperatorForRow(matchingFlow, rowIndex, value))}/>
            </div>}
        </React.Fragment>
      )}
    </div>
    <Button
      size="medium"
      className={styles.AddConditionButton}
      onClick={() => onChange(MF.appendRow(matchingFlow, "OR", -1))}>
        <FormattedMessage id="tabs.logic.conditions.add" />
    </Button>
  </>;


type MatchingFlowRowProps = {
  matchingFlow: ParsedMatchingFlow;
  rowIndex: number;
  availableRules: MatchingRule[];
  onChange: (value: ParsedMatchingFlow) => void;
};

const MatchingFlowTableRow = ({ matchingFlow, rowIndex, availableRules, onChange }: MatchingFlowRowProps) => {
  return <div className={styles.MatchingFlowTableRow}>
    <div className={styles.MatchingFlowTableCell}>
      <div
        className={styles.DragHandler}
      >
        <div className={styles.DragHandlerInnerLeft} />
        <div className={styles.DragHandlerInnerRight} />
      </div>
    </div>
    <div className={styles.MatchingFlowTableCell}>{rowIndex + 1}.</div>
    <div className={styles.MatchingFlowTableCell}>
      {matchingFlow[rowIndex].group.map(({rule, nextOperator}, i) =>
        <React.Fragment key={i}>
          <div className={styles.MatchingFlowRule}>
            <Select
              value={String(rule)}
              title="rule"
              options={availableRules.map(r => ({ label: r.targetField + ' | ' + r.id, value: r.id }))}
              onChange={() => {
                //TODO FIX
              }} />
          </div>
          {nextOperator &&
            <OperatorSelect
              operator={nextOperator}
              name={`operator-${rowIndex}-${i}`}
              onChange={operator => onChange(MF.setNextOperatorForRule(matchingFlow, rowIndex, i, operator))} />}
        </React.Fragment>
      )}
      <button
        className={merge(styles.Button, styles.AddButton)}
        onClick={() =>  onChange(MF.appendRule(matchingFlow, rowIndex, 'AND', -1))}>
        <PlusIcon />
      </button>
    </div>
    <div className={styles.MatchingFlowTableCell}>
      <button
        className={merge(
          styles.Button,
          styles.RemoveButton,
        )}
        onClick={() => onChange(MF.removeRow(matchingFlow, rowIndex))}>
        <TrashIcon />
      </button>
    </div>
  </div>;
}

const operatorOptions = [
  {label: 'AND', value: 'AND'},
  {label: 'OR', value: 'OR'}
];

const OperatorSelect = ({ operator, name, onChange }: { operator: MatchingFlowOperator, name: string, onChange: (value: MatchingFlowOperator) => void }) =>
  <div className={styles.MatchingFlowOperator}>
    <Select value={operator}
      title={name}
      options={operatorOptions}
      onChange={onChange}
    />
  </div>;
