import React, { useState } from 'react';
import { SectionContainer } from '@rossum/rossum-ui/SectionContainer';
import { Button } from '@rossum/rossum-ui/Button';
import styles from './styles.module.sass';
import { FormattedMessage } from 'react-intl';
import Headline from '../../components/Headline';
import Subtitle from '../../components/Subtitle';
import ItemsSelector from '../../components/ItemsSelector';
import { Modal, ModalTitle } from '@rossum/rossum-ui/Modal';
import { useRemoveTabDataHook, useRemoveWebhooksHook } from './hooks';

const Remove = () => {
  const { queues, setQueues, dataMatchingHooks } = useRemoveTabDataHook();
  const { submit, submitPending } = useRemoveWebhooksHook();
  const [selectedQueues, setSelectedQueues] = useState<Array<number>>([]);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <>
      <Headline>
        <FormattedMessage id="tabs.remove.title" />
      </Headline>

      <SectionContainer
        title={<FormattedMessage id="tabs.remove.currentExtensions.title" />}
      >
        <Subtitle>
          <FormattedMessage id="tabs.remove.currentExtensions.description" />
        </Subtitle>

        <ItemsSelector
          items={queues}
          selectedItems={selectedQueues}
          setSelectedItems={setSelectedQueues}
        />

        <Button
          size="large"
          color="Danger"
          className={styles.RemoveButton}
          onClick={() => setModalOpen(true)}
          disabled={!selectedQueues.length || submitPending}
        >
          <FormattedMessage id="tabs.remove.removeButton" />
        </Button>
        <Modal isOpen={isModalOpen}>
          <ModalTitle
            text={
              <FormattedMessage
                id="tabs.remove.modal.title"
                values={{ count: selectedQueues.length }}
              />
            }
            onCancel={() => setModalOpen(false)}
          />
          <div className={styles.ModalBody}>
            <FormattedMessage
              id="tabs.remove.modal.body"
              values={{ count: selectedQueues.length }}
            />
          </div>
          <div className={styles.ModalButtons}>
            <Button
              size="large"
              color="Secondary"
              onClick={() => setModalOpen(false)}
            >
              <FormattedMessage id="tabs.remove.modal.cancel" />
            </Button>

            <Button
              size="large"
              color="Danger"
              onClick={() =>
                submit(
                  selectedQueues.map((queueId) => ({
                    queue_id: queueId,
                    hook_id: Number(
                      queues
                        .find(({ id }) => id === queueId)
                        ?.hooks.find((hookUrl) =>
                          dataMatchingHooks.find(({ url }) => hookUrl === url),
                        )
                        ?.split('/')
                        .reverse()[0],
                    ),
                  })),
                  () => {
                    setModalOpen(false);
                    setSelectedQueues([]);
                    setQueues((_queues) =>
                      _queues.filter(({ id }) => !selectedQueues.includes(id)),
                    );
                  },
                  () => {
                    setModalOpen(false);
                  },
                )
              }
            >
              <FormattedMessage id="tabs.remove.removeButton" />
            </Button>
          </div>
        </Modal>
      </SectionContainer>
    </>
  );
};

export default Remove;
