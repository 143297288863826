import React, { ReactNode } from 'react';
import styles from './styles.module.sass';
import { merge } from '@rossum/rossum-ui/helpers';

type Props = {
  children: ReactNode;
  className?: string;
};

const Box = ({ children, className }: Props) => (
  <div className={merge(styles.Box, className)}>{children}</div>
);

export default Box;
