import { ReactNode, useContext, useEffect } from 'react';
import { authContext, useProvideAuth } from './hooks';

type AuthProviderProps = { children: ReactNode };

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const auth = useProvideAuth();

  useEffect(() => {
    if (!auth.isLoggedIn) {
      auth.checkSession();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => useContext(authContext);
