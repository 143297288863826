import styles from './styles.module.sass';
import { FormattedMessage } from 'react-intl';
import { Tooltip as TooltipUI } from '@rossum/rossum-ui/Tooltip';
import InfoOutlineIcon from 'mdi-react/InfoOutlineIcon';
import { gray, boldText, ul, li } from '../../lib/MessageValues';
import { merge } from '@rossum/rossum-ui/helpers';

type Props = {
  translationId: string;
  limitedWidth?: boolean;
};

const InfoTooltip = ({ translationId, limitedWidth }: Props) => {
  return (
    <TooltipUI
      contentClassName={merge(
        styles.Tooltip,
        limitedWidth && styles.LimitedWidth,
      )}
      content={
        <FormattedMessage
          id={translationId}
          values={{ ul, li, gray, boldText }}
        />
      }
    >
      <div className={styles.Icon}>
        <InfoOutlineIcon />
      </div>
    </TooltipUI>
  );
};

InfoTooltip.defaultProps = {
  limitedWidth: true,
};

export default InfoTooltip;
