import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import { Button } from '@rossum/rossum-ui/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './styles.module.sass';

import Header from '../components/Header';
import FieldWrapper from '../components/Form/FieldWrapper';
import { Input } from '@rossum/rossum-ui/Input';
import FormErrorMessage from '../components/Form/FormErrorMessage';

const LoginPage = () => {
  const { error, isLoggedIn, login } = useAuth();
  const intl = useIntl();
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  return isLoggedIn ? (
    <Redirect to="/upload-companies" />
  ) : (
    <div className={styles.Container}>
      <div className={styles.Header}>
        <Header />
      </div>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          if (username && password) {
            login({ username, password });
          }
        }}
      >
        <FieldWrapper>
          {error && (
            <FormErrorMessage align="center">
              <FormattedMessage id={`loginPage.errors.${error}`} />
            </FormErrorMessage>
          )}
          <Input
            onChange={setUsername}
            value={username}
            placeholder={intl.formatMessage({
              id: 'loginPage.username.placeholder',
            })}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Input
            onChange={setPassword}
            value={password}
            placeholder={intl.formatMessage({
              id: 'loginPage.password.placeholder',
            })}
            type="password"
          />
        </FieldWrapper>
        <Button size="large" type="submit" className={styles.LoginButton}>
          <FormattedMessage id="loginPage.login" />
        </Button>
      </form>
    </div>
  );
};

export default LoginPage;
