// @ts-nocheck
/* eslint-disable */
export const addInitSmartlook = () => {
  window.initSmartlook = () => {
    window.smartlook ||
      (function (d) {
        var o = (window.smartlook = function () {
          o.api.push(arguments);
        }),
          h = d.getElementsByTagName('head')[0];
        var c = d.createElement('script');
        o.api = new Array();
        c.async = true;
        c.type = 'text/javascript';
        c.charset = 'utf-8';
        c.src = 'https://rec.smartlook.com/recorder.js';
        h.appendChild(c);
      })(document);
    window.smartlook('init', '88b3b7face6fc81182b74e2b3109a237f3e53d1f');
  };
}
