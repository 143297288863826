const en = {
  "app.goBackToRossum": "Go back to Rossum",
  "app.logout": "Logout",
  "app.pending": "Pending...",
  "app.messages.error.unexpected": "Something went wrong.",
  "app.messages.info.title": "Done",
  "app.messages.error.title": "Error",
  "components.header.title": "Data Matching",
  "components.itemsSelector.empty": "No items to select",
  "components.promoContainer.helpCenterLink": "Read a guide about data matching extension",
  "loginPage.errors.unauthorized": "Please provide valid credentials",
  "loginPage.errors.sessionExpired": "Your session has expired! Please sign in again",
  "loginPage.login": "Sign in",
  "loginPage.pageTitle": "Data Matching",
  "loginPage.password.placeholder": "Password",
  "loginPage.username.placeholder": "Username",
  "tabs.logic.confirmLimitations.description": "If match is not found, should the user have the possibility to confirm the document?",
  "tabs.logic.confirmLimitations.title": "Confirm limitations",
  "tabs.logic.conditions.add": "Add a condition",
  "tabs.logic.conditions.title": "Conditions",
  "tabs.logic.conditions.description": "With conditions you can set up combinations of above specified rules which need to be fulfilled in order to claim a match. The order of conditions specifies the order in which conditions will be performed to find a match. E.g. vendor is claimed to be matched if there is both match by Vendor Name and Vendor VAT number.",
  "tabs.logic.errors.unableToSave": "Unable to save the rules!",
  "tabs.logic.messages.success": "Matching logic was successfully created.",
  "tabs.logic.guide.title": "Guide",
  "tabs.logic.guide.description_1": "Here you set up matching rules for your uploaded data.",
  /* eslint-disable-next-line no-multi-str */
  "tabs.logic.guide.description_2": "<ol>\
    <li>Choose what data to work with by selecting the queue and dataset where you uploaded the master data.</li>\
    <li>Match the fields in Rossum with columns in your data.</li>\
    <li>Choose what should happen if no match is found.</li>\
    </ol>",
  "tabs.logic.queue.title": "Queue",
  "tabs.logic.queue.description": "Queue selection",
  "tabs.logic.datasetSelection.title": "Dataset selection",
  "tabs.logic.datasetSelection.description": "Select the dataset for which the matching rules will apply.",
  "tabs.logic.noAvailableDataset": "Previously saved dataset is not available anymore.",
  "tabs.logic.rules.desyncedMasterData": "Selected master data column does not appear in the newly uploaded master data.",
  "tabs.logic.noQueuesWithConfig": "You haven't configured matching field for any of your queues yet.",
  "tabs.logic.rules.description": "Click on the blue check to define fields that should be used for matching, and the matching technique. Specify rules in the order as they should be applied.",
  "tabs.logic.rules.masterDataColumn": "Master data column",
  "tabs.logic.rules.masterDataColumn.tooltip": "List of uploaded master data columns/keys.",
  "tabs.logic.rules.matchingTechnique": "Matching technique",
  "tabs.logic.rules.matchingTechnique.tooltip": "Select one of the visible fields defined in the extraction schema that should be used for matching.",
  "tabs.logic.rules.targetField": "Rossum captured field",
  "tabs.logic.rules.targetField.tooltip": "Select one of the visible fields defined in the extraction schema that should be used for matching.",
  "tabs.logic.rules.threshold": "Threshold",
  "tabs.logic.rules.threshold.tooltip": "The similarity threshold between 0 and 1 above which strings are considered to match. If left empty, default threshold 0.3 will be used.",
  "tabs.logic.rules.threshold.notAvailable": "No threshold",
  "tabs.logic.rules.threshold.invalidValue": "Has to be number in range <0.3, 1.0>",
  "tabs.logic.rules.title": "Rules",
  "tabs.logic.submitButton": "Save",
  "tabs.logic.submitPending": "Saving...",
  "tabs.logic.title": "Create matching logic",
  "tabs.remove.currentExtensions.description": "Choose queues from which you would like to remove the extension",
  "tabs.remove.currentExtensions.title": "Current extensions",
  "tabs.remove.messages.success": "{count, plural, one {Extension was} other {Extensions were}} successfully removed.",
  "tabs.remove.modal.body": "Do you really want to remove the chosen {count, plural, one {extension} other {extensions}}?",
  "tabs.remove.modal.cancel": "Cancel",
  "tabs.remove.modal.title": "Remove {count, plural, one {extension} other {extensions}}",
  "tabs.remove.removeButton": "Remove",
  "tabs.remove.success": "Extension successfully removed from queue: <boldText>{queueName}</boldText>",
  "tabs.remove.title": "Remove extension",
  "tabs.setup.title": "Set up a matching field",
  "tabs.setup.guide.title": "Matching field setup guide",
  "tabs.setup.guide.description_1": "Here you create the field where you will see your matched data. The field will appear on your validation screen among other fields so these settings will affect your Rossum extraction schema.",
  "tabs.setup.guide.description_2": "This step you usually need to do just once for a specific dataset on a given queue. If you need to edit an already existing matching field, do it in the extraction schema as here you can only define new fields.",
  "tabs.setup.guide.link": "Read a detailed guide about setting up the matching field",
  "tabs.setup.queueSelection.title": "Queue selection",
  "tabs.setup.queueSelection.description": "Choose the queue where you want this particular field to appear.",
  "tabs.setup.datasetSelection.title": "Dataset selection",
  "tabs.setup.datasetSelection.description": "Choose the dataset where you store the data which shall be matched in this field.",
  "tabs.setup.noDatasets": "You haven't uploaded any datasets yet.",
  "tabs.setup.matchingField.title": "Matching field",
  "tabs.setup.matchingField.fieldDef.title": "Field definition",
  "tabs.setup.matchingField.fieldDef.description": "Set a label for the matching field that will be visible in your schema on the validation screen. It’s ID needs to be unique.",
  "tabs.setup.matchingField.fieldDef.placementDescription": "Choose where you would like to place this new field among your existing fields. Define before or after which field it will be positioned.",
  "tabs.setup.matchingField.fieldDef.schemaId": "ID",
  "tabs.setup.matchingField.fieldDef.label": "Label",
  "tabs.setup.matchingField.referenceField.label": "Field",
  "tabs.setup.matchingField.position.label": "Position",
  "tabs.setup.matchingField.position.placeholder": "Set field position",
  "tabs.setup.matchingField.position.before": "Before",
  "tabs.setup.matchingField.position.after": "After",
  "tabs.setup.matchingField.referenceField.placeholder": "Set reference field",
  "tabs.setup.matchingField.referencefield.error": "Failed to fetch schema",
  "tabs.setup.matchingField.fieldDef.placeholder.label": "Set field label...",
  "tabs.setup.matchingField.fieldDef.placeholder.id": "Set field ID...",
  "tabs.setup.messages.success": "Matching field was successfully set up.",
  "tabs.setup.matchingField.additionalInfo.title": "Additional information to be shown",
  "tabs.setup.matchingField.additionalInfo.description": "Choose what other information should be shown in the matching field apart from the unique identifier if the matching is successful (i.e. apart from the vendor name you might also want to show its address).",
  "tabs.setup.matchingField.uniqueIdentifier.label": "Unique identifier",
  "tabs.setup.matchingField.uniqueIdentifier.tooltip": "Information uniquely identifying a record in your database, such as a Vendor ID in your vendor table where is e.g. a Vendor Name and a Vendor Address. You specified this when uploading your Master Data.",
  "tabs.setup.matchingField.extraInfo.label": "Extra information",
  "tabs.setup.matchingField.extraInfo.tooltip": "When a match is found, by default is displayed a unique identifier and a matched value, such as a Vendor ID and a Vendor Name. You may choose here to show also e.g. a Vendor Address.",
  "tabs.setup.setExtraIdentifier.default": "Default",
  "tabs.setup.setExtraIdentifier.placeholder": "Select extra identifier from the options",
  "tabs.setup.submitButton": "Save",
  "tabs.setup.submitPending": "Saving...",
  "tabs.upload.dataset.title": "Dataset",
  "tabs.upload.dataset.description": "Specify the name of the dataset you want to upload your data to. E.g. for uploading list of vendors you might want to call your dataset \"vendors\". If the dataset you specify already holds some data, uploading a new file will overwrite that data. See your existing datasets on the next tab if needed.",
  "tabs.upload.dataset.placeholder": "Enter name of the dataset",
  "tabs.upload.downloadSamples": "<boldText><white>Download one of our sample files</white></boldText> to see how the format of the file can look like",
  "tabs.upload.dropzone.buttonText": "Choose File",
  "tabs.upload.dropzone.file": "File",
  "tabs.upload.dropzone.fileSizeError": "Chosen file is too large. File size limit is 50 MB.",
  "tabs.upload.dropzone.noFileChosen": "No file chosen",
  "tabs.upload.dropzone.note": "The file format should be one of .json, .xml, .csv, .xls or .xlsx",
  "tabs.upload.dropzone.title": "Select the file containing list of companies",
  "tabs.upload.encoding.description": "Select the encoding of your file",
  /* eslint-disable-next-line no-multi-str */
  "tabs.upload.encoding.tooltip": "<ul>\
    <li><boldText>UTF-8</boldText><gray> - suitable for files without any special characters. Also the default encoding.</gray></li>\
    <li><boldText>Windows-1250</boldText><gray> - suitable for Central and Southeast European languages that use Latin script.</gray></li>\
    <li><boldText>Windows-1252</boldText><gray> - suitable for Western languages with special characters.</gray></li>\
    <li><boldText>Latin-1</boldText><gray> - suitable for Western Europe and Americas. If UTF-8 didn’t work for you, try this encoding.</gray></li>\
    <li><boldText>UTF-8-sig</boldText><gray> - a special case of UTF-8 with BOM signature</gray></li>\
    </ul>",
  "tabs.upload.encoding.title": "Encoding",
  "tabs.upload.formats.csv": "CSV",
  "tabs.upload.formats.json": "JSON",
  "tabs.upload.formats.xlsx": "XLSX",
  "tabs.upload.formats.xsd": "XSD",
  "tabs.upload.guide.title": "Master data upload guide",
  "tabs.upload.guide.description": "Here you can upload your master data which then can be used for automatic data matching on any queue in your organization. Define standard or custom names and upload the file below. After successful upload, continue with the setup on the following tabs.",
  "tabs.upload.guide.standardColumns.title": "Standard columns",
  "tabs.upload.guide.standardColumns.description": "When saving data, Rossum performs basic data clean up on columns which have standard names.",
  "tabs.upload.guide.customColumns.title": "Custom columns",
  "tabs.upload.guide.customColumns.description": "If you upload custom columns, try to clean up the data before upload in order to achieve good data matching results.",
  "tabs.upload.guide.readMore": "Read more",
  "tabs.upload.matchingCodeColumn.title": "Unique Identifier in Uploaded Data",
  "tabs.upload.matchingCodeColumn.description": "Define the name of the column in the uploaded spreadsheet (or element/key in XML/JSON file) that holds the value that should be exported from Rossum when a record is matched. This is typically a unique identifier of a record in your system, e.g. SAP ID, Code, Supplier ID or similar.",
  "tabs.upload.matchingCodeColumn.placeholder": "Fill in the column/element name",
  "tabs.upload.samples.delimiterInfo": "The delimiter of the values in the CSV file should be a semicolon.",
  "tabs.upload.samples.listOfCompanies": "Sample list of companies",
  "tabs.upload.samples.poData": "Sample PO data",
  "tabs.upload.samples.schema": "Schema",
  "tabs.upload.submitButton": "Upload",
  "tabs.upload.submitPending": "Uploading...",
  "tabs.upload.title": "Upload master data",
  "tabs.upload.messages.success": "Your data is being processed. Please stay logged in to receive an email when your data is ready.",
}

export default en;
