import { CircularProgress } from '@mui/material';
import styles from './utilityComponents.module.sass';

export type ChildrenProps = { children: React.ReactNode };

export const Paragraph = ({ children }: ChildrenProps) => (
  <p className={styles.Paragraph}>{children}</p>
);

export const HeadlineLabel = ({ children }: ChildrenProps) => (
  <p className={styles.Headline}>{children}</p>
);

export const Loader = () => (
  <CircularProgress sx={{ marginLeft: 2 }} size={20} color="inherit" />
);
