import styles from './styles.module.sass';
import { merge } from '@rossum/rossum-ui/helpers';
import { FormattedMessage } from 'react-intl';

type Props = {
  items: Array<{ id: number; name: string }>;
  selectedItems: number[];
  setSelectedItems: (ids: number[]) => void;
};

const ItemsSelector = ({ items, selectedItems, setSelectedItems }: Props) => {
  return (
    <div className={styles.Items}>
      {items.length ? (
        items.map(({ id, name }) => {
          const active = selectedItems.includes(id);

          return (
            <div
              className={merge(styles.Item, active && styles.ItemActive)}
              onClick={(e) => {
                if (e.metaKey || e.ctrlKey) {
                  return setSelectedItems(
                    active
                      ? selectedItems.filter((_id) => _id !== id)
                      : [...selectedItems, id],
                  );
                }
                return setSelectedItems(
                  active && !(selectedItems.length > 1) ? [] : [id],
                );
              }}
              key={id}
            >
              {name}
            </div>
          );
        })
      ) : (
        <div className={styles.Empty}>
          <FormattedMessage id="components.itemsSelector.empty" />
        </div>
      )}
    </div>
  );
};

export default ItemsSelector;
