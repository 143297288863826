import axios, { CancelTokenSource } from "axios";
import { useEffect } from "react"
import { authGet } from "../lib/api"
import { convertKeysInObject } from "../lib/helpers";
import { Organization, User } from "../types";
import { initGTM } from "./gtm";
import { initSmartlook } from "./smartlook";
import camelCase from 'lodash/camelCase'

const initTracking = async (source: CancelTokenSource) => {
  const user = await authGet<User>('/auth/user', { cancelToken: source.token })
    .then(({ data }) => data)
    .then(convertKeysInObject<User>(camelCase))
    .catch(() => ({} as User));

  const organizationId = user.organization?.split('/organizations/')[1]

  const shouldTrack =
    organizationId ?
      await authGet<Organization>(`/organizations/${organizationId}`, { cancelToken: source.token })
        .then(({ data }) => data)
        .then(convertKeysInObject<Organization>(camelCase))
        .then(({ uiSettings: { trackingEnabled }, isTrial }) => trackingEnabled === undefined ? isTrial : trackingEnabled)
        .catch(() => false)
      : false;

  if (window.Rollbar) {
    window.Rollbar.configure({
      payload: { person: { username: user.username, id: user.id } },
    });
  }

  if (shouldTrack) {
    initGTM();
    initSmartlook(user);
  }
}

export const useTracking = (isLoggedIn: boolean) => {
  useEffect(() => {
    const source = axios.CancelToken.source();
    if (isLoggedIn) {
      initTracking(source);
    }

    return () => source.cancel();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}
