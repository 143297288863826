import { ReactNode } from 'react';
import styles from './styles.module.sass';
import { merge } from '@rossum/rossum-ui/helpers';

type Props = {
  children: ReactNode;
  withMargin?: boolean;
};

const Subtitle = ({ children, withMargin }: Props) => {
  return (
    <div
      className={merge(
        styles.Subtitle,
        withMargin && styles.SubtitleWithMargin,
      )}
    >
      {children}
    </div>
  );
};

export default Subtitle;
