import { createContext, useState } from "react";
import { HandleLoginMeta, LoginPayload, LoginResponse, LoginWithTokenPayload, LogoutConfig } from './types';
import { useLocation } from "react-router-dom";
import { parse } from "query-string";
import axios, { AxiosResponse } from 'axios';
import { authGet } from "../lib/api";
import { LOGGED_IN_WITH_TOKEN, SECURE_TOKEN } from "../lib/localStorage";
import { apiPrefix } from "../config";
import { useQueryClient } from "react-query";

const defaultPending = true;
const defaultLoggedIn = false;
const defaultError = '';

const noop = () => { };

export const authContext = createContext({
  authPending: defaultPending,
  error: defaultError,
  checkSession: noop,
  login: (_: LoginPayload) => { },
  logout: (_: LogoutConfig) => { },
  isLoggedIn: defaultLoggedIn,
});

export const useProvideAuth = () => {
  const [isLoggedIn, setLoggedIn] = useState<boolean>(defaultLoggedIn);
  const [authPending, setPending] = useState<boolean>(defaultPending);
  const [error, setError] = useState<string>(defaultError);
  const queryClient = useQueryClient();
  const { hash } = useLocation();

  const checkSession = () =>
    hash.includes('token')
      ? loginWithToken({ token: `${parse(hash).token}` })
      : Promise.all(
        !localStorage.getItem(SECURE_TOKEN)
          ? [Promise.resolve({ status: 0 }), Promise.resolve({ status: 0 })]
          : [
            axios.get(`${apiPrefix}/helpers/encodings`),
            authGet('/auth/user'),
          ],
      )
        .then(([dmCheck, beCheck]) => {
          const success = dmCheck.status === 200 && beCheck.status === 200;

          setLoggedIn(success);

          if (!success) {
            localStorage.removeItem(SECURE_TOKEN);
          }
        })
        .catch(() => {
          setLoggedIn(false);
          localStorage.removeItem(SECURE_TOKEN);
        })
        .finally(() => setPending(false));

  const login = ({ username, password }: LoginPayload) =>
    handleLogin(axios.post<LoginPayload, AxiosResponse<LoginResponse>>(`${apiPrefix}/auth/login`,
      {
        username,
        password,
      },
    ), { loggingInWithToken: false });

  const loginWithToken = ({ token }: LoginWithTokenPayload) =>
    handleLogin(axios.get<{}, AxiosResponse<LoginResponse>>(`${apiPrefix}/helpers/encodings`, {
      headers: {
        'Authorization': `Token ${token}`
      },
    }), { loggingInWithToken: true });

  const handleLogin = (
    loginRequest: Promise<AxiosResponse<LoginResponse>>,
    meta: HandleLoginMeta,
  ) => {
    setPending(true);
    setError(defaultError);

    loginRequest
      .then(({ data: { token: responseToken }, config: { headers } }) => {
        const token = responseToken || headers?.['Authorization']?.replace('Token ', '');

        if (!token) {
          throw new Error('Unauthorized');
        }

        localStorage.setItem(SECURE_TOKEN, token);
        localStorage.setItem(LOGGED_IN_WITH_TOKEN, String(meta.loggingInWithToken))
        setLoggedIn(true);
      })
      .catch(() => {
        setLoggedIn(false);
        setError('unauthorized');
      })
      .finally(() => setPending(false));
  };

  const logout = ({ shouldCloseWindow, sessionExpired }: LogoutConfig) => {
    const handleLogout = () => {
      setLoggedIn(false);
      localStorage.removeItem(SECURE_TOKEN);
      if (sessionExpired) {
        setError('sessionExpired');
      }
      setPending(false);
    };

    setPending(true);

    queryClient.clear();

    if (shouldCloseWindow) {
      window.close();
      return setTimeout(handleLogout);
    }

    axios.post(`${apiPrefix}/auth/logout`).catch(noop).finally(handleLogout);
  };

  return {
    error,
    isLoggedIn,
    login,
    logout,
    checkSession,
    authPending,
  };
};
