export type SelectOption = {
  label: string;
  value: string;
}

export type MatchingRule = {
  targetField: string;
  masterDataKeys: string[];
  matchingTechnique: string;
  compound_match: boolean;
  joining_char: string;
  threshold?: string;
  id: string;
};

type MatchingSpecOptions =
  | 'limitations'
  | 'matchableFields'
  | 'matchingTechniques';

export type MatchingSpecs = Record<MatchingSpecOptions, Array<SelectOption>>;

type MatchingSpecResponseKey =
  | 'limitations'
  | 'matchable_fields'
  | 'matching_techniques';

export type MatchingSpecsResponse = Record<MatchingSpecResponseKey, Array<SelectOption>>;

export type Rules = Array<{
  fuzzy: boolean;
  field_to_match: string;
  matching_keys: string[];
  compound_match: boolean;
  joining_char: string;
  threshold: number;
}>;

export type ConfigMatchingRule = {
  rules: Rules;
  dataset: string;
  message_type: string;
  matching_label_extra_identifier?: string;
}

export type QueueConfig = {
  matching_rules: ConfigMatchingRule[] | null;
};

export type QueueSelectOption = SelectOption & DataMatchingQueue

export type RossumQueue = {
  id: number;
  name: string;
  hooks: Array<string>;
  workspace: string;
  schema: string;
};

export type ReferenceRelationT = 'before' | 'after';

export type MatchingFieldConfig = {
  dataset: string;
  extra_identifier: string | null;
  matching_enum: string;
  matching_field_label: string;
  reference_relation: ReferenceRelationT,
  reference_field: string,
}

export type DataMatchingQueue = {
  id: number;
  name: string;
  schema: string;
  configuration: QueueConfig;
  matching_config: MatchingFieldConfig[];
};

export const isDataMatchingQueue =
  (queue: DataMatchingQueue | RossumQueue): queue is DataMatchingQueue =>
    'matching_config' in queue;

export type Hook = {
  id: number;
  name: string;
  url: string;
  config: { url: string }
}

export type ResponseWithMessages = {
  success: boolean;
  messages: Array<string>;
}

export type User = {
  organization: string;
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  username: string;
  uiSettings: {
    hints?: { [key: string]: boolean }
  }
}

export type Organization = {
  uiSettings: { trackingEnabled?: boolean },
  isTrial: boolean;
}

export type Workspace = {
  name: string;
  url: string;
}

export type Dataset = {
  dataset: string;
  unique_identifier: string;
  master_data_keys: string[];
}

export type DatasetOption =
  Omit<Dataset, 'master_data_keys'> &
  SelectOption &
  {
    master_data_keys: SelectOption[];
  };

type Base = {
  id: string;
  label: string;
}

export type Datapoint = Base & {
  category: 'datapoint'
}

export type Tuple = Base & {
  category: 'tuple',
  children: Array<Datapoint>
}

export type MultivalueTable = Base & {
  category: 'multivalue',
  children: Tuple
}

export type MultivalueSimple = Base & {
  category: 'multivalue';
  children: Datapoint
}

export type SectionChild = Datapoint | MultivalueTable | MultivalueSimple;

export type SchemaSection = Base & {
  category: 'section';
  children: Array<SectionChild>;
}

export type Schema = {
  id: number,
  content: Array<SchemaSection>
}
