import React from 'react';
import { Logo } from '@rossum/rossum-ui/Logo';
import styles from './styles.module.sass';
import { FormattedMessage } from 'react-intl';

const Header = () => (
  <div className={styles.Header}>
    <Logo />
    <div className={styles.Headline}>
      <FormattedMessage id="components.header.title" />
    </div>
  </div>
);

export default Header;
