import React, { ChangeEvent, useEffect, useState } from 'react';
import { SectionContainer } from '@rossum/rossum-ui/SectionContainer';
import { Dropzone } from '@rossum/rossum-ui/Dropzone';
import { Button } from '@rossum/rossum-ui/Button';
import { Select } from '@rossum/rossum-ui/Select';
import styles from './styles.module.sass';
import { FormattedMessage, useIntl } from 'react-intl';
import Headline from '../../components/Headline';
import { white, boldText } from '../../lib/MessageValues';
import SampleBox from './components/SampleBox';
import { merge } from '@rossum/rossum-ui/helpers';
import Subtitle from '../../components/Subtitle';
import { useUploadHook, useUploadTabDataHook } from './hooks';
import { Input } from '@rossum/rossum-ui/Input';
import { fileLimit, standardColumnsArticle } from '../../config';
import InfoTooltip from '../../components/InfoTooltip';
import FormErrorMessage from '../../components/Form/FormErrorMessage';
import sharedStyles from '../../lib/styles.module.sass';
import { CircularProgress } from '@mui/material';

const Upload = () => {
  const intl = useIntl();
  const [dragging, setDragging] = useState<boolean>(false);
  const { encodingOptions } = useUploadTabDataHook();
  const { submit, submitPending } = useUploadHook();

  const [encoding, setEncoding] = useState<string>(encodingOptions[0]?.value);
  const [dataset, setDataset] = useState<string>('companies');
  const [matchingCodeColumn, setMatchingCodeColumn] = useState<string>('');
  const [file, setFile] = useState<File | null>(null);
  const [fileSizeError, setFileSizeError] = useState<boolean>(false);

  useEffect(() => {
    setEncoding(encodingOptions[0]?.value);
  }, [encodingOptions]);

  const submitDisabled = !(encoding && file && matchingCodeColumn && dataset);

  return (
    <>
      <Headline>
        <FormattedMessage id="tabs.upload.title" />
      </Headline>

      <SectionContainer
        title={<FormattedMessage id="tabs.upload.guide.title" />}
      >
        <Subtitle>
          <FormattedMessage id="tabs.upload.guide.description" />
        </Subtitle>

        <SampleBox
          isInfoBox
          id="standardColumns"
          title={
            <FormattedMessage id="tabs.upload.guide.standardColumns.title" />
          }
          description={
            <FormattedMessage id="tabs.upload.guide.standardColumns.description" />
          }
          readMoreLink={standardColumnsArticle}
        />
        <SampleBox
          isInfoBox
          id="customColumns"
          title={
            <FormattedMessage id="tabs.upload.guide.customColumns.title" />
          }
          description={
            <FormattedMessage id="tabs.upload.guide.customColumns.description" />
          }
        />
      </SectionContainer>

      <SectionContainer
        title={<FormattedMessage id="tabs.upload.dataset.title" />}
      >
        <Subtitle>
          <FormattedMessage id="tabs.upload.dataset.description" />
        </Subtitle>
        <div className={styles.Input}>
          <Input
            onChange={setDataset}
            value={dataset}
            placeholder={intl.formatMessage({
              id: 'tabs.upload.dataset.placeholder',
            })}
          />
        </div>
      </SectionContainer>

      <SectionContainer
        title={<FormattedMessage id="tabs.upload.dropzone.file" />}
      >
        <div className={merge(styles.WithBorder, dragging && styles.Dragging)}>
          <Dropzone
            buttonOnSide="left"
            dragging={dragging}
            setDragging={setDragging}
            title={<FormattedMessage id={'tabs.upload.dropzone.title'} />}
            text={
              file ? (
                file.name
              ) : (
                <FormattedMessage id={'tabs.upload.dropzone.noFileChosen'} />
              )
            }
            buttonText={
              <FormattedMessage id={'tabs.upload.dropzone.buttonText'} />
            }
            note={<FormattedMessage id={'tabs.upload.dropzone.note'} />}
            uploadableFormats={[
              'application/json',
              'application/xml',
              'text/xml',
              'text/csv',
              '.csv',
              'application/vnd.ms-excel',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ]}
            key={file?.name}
            multiple={false}
            onAdd={(e: ChangeEvent<HTMLInputElement>) => {
              if (e.target.files?.length) {
                const selectedFile = e.target.files[0];
                if (selectedFile.size / 1024 / 1024 > fileLimit) {
                  return setFileSizeError(true);
                }
                setFileSizeError(false);
                return setFile(e.target.files[0]);
              }

              return setFile(null);
            }}
          />
        </div>
        {fileSizeError && (
          <FormErrorMessage>
            <FormattedMessage id="tabs.upload.dropzone.fileSizeError" />
          </FormErrorMessage>
        )}
        <Subtitle withMargin>
          <FormattedMessage
            id="tabs.upload.downloadSamples"
            values={{ white, boldText }}
          />
        </Subtitle>

        <SampleBox
          id="sample_companies"
          title={<FormattedMessage id="tabs.upload.samples.listOfCompanies" />}
          description={
            <FormattedMessage id="tabs.upload.samples.delimiterInfo" />
          }
          samples={['json', 'xlsx', 'csv']}
        />
        <SampleBox
          id="sample_po_data"
          title={<FormattedMessage id="tabs.upload.samples.poData" />}
          samples={['json']}
        />
        <SampleBox
          id="companies_schema"
          title={<FormattedMessage id="tabs.upload.samples.schema" />}
          samples={['json', 'xsd']}
        />
      </SectionContainer>

      <SectionContainer
        title={<FormattedMessage id="tabs.upload.encoding.title" />}
      >
        <Subtitle>
          <FormattedMessage id="tabs.upload.encoding.description" />
        </Subtitle>

        <div className={styles.Select}>
          <Select
            options={encodingOptions}
            title="endonding-select"
            onChange={setEncoding}
            value={encoding}
          />
          <InfoTooltip
            limitedWidth={false}
            translationId="tabs.upload.encoding.tooltip"
          />
        </div>
      </SectionContainer>

      <SectionContainer
        title={<FormattedMessage id="tabs.upload.matchingCodeColumn.title" />}
      >
        <Subtitle>
          <FormattedMessage id="tabs.upload.matchingCodeColumn.description" />
        </Subtitle>
        <div className={styles.Input}>
          <Input
            onChange={setMatchingCodeColumn}
            value={matchingCodeColumn}
            placeholder={intl.formatMessage({
              id: 'tabs.upload.matchingCodeColumn.placeholder',
            })}
          />
        </div>
      </SectionContainer>

      <Button
        size="large"
        className={sharedStyles.SubmitButton}
        disabled={submitDisabled || submitPending}
        onClick={() =>
          !submitDisabled &&
          submit({ matchingCodeColumn, file: file, encoding, dataset }, () => {
            setFile(null);
            setEncoding(encodingOptions[0]?.value);
          })
        }
      >
        {submitPending ? (
          <>
            <CircularProgress
              color="inherit"
              size={16}
              sx={{ marginRight: 1 }}
            />
            <FormattedMessage id="tabs.upload.submitPending" />
          </>
        ) : (
          <FormattedMessage id="tabs.upload.submitButton" />
        )}
      </Button>
    </>
  );
};

export default Upload;
