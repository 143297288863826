import React, { ReactNode } from 'react';
import styles from './styles.module.sass';

type Props = {
  children: ReactNode;
};

const Headline = ({ children }: Props) => (
  <h1 className={styles.Headline}>{children}</h1>
);

export default Headline;
