import { useEffect, useState } from "react";
import { SelectOption, ResponseWithMessages } from "../../types";
import { useHandleErrorHook } from "../../lib/api";
import axios, { AxiosResponse } from 'axios';
import { apiPrefix } from "../../config";
import { useSetMessage } from "../../MessagesProvider";
import { useIntl } from "react-intl";

export const useUploadTabDataHook = () => {
  const source = axios.CancelToken.source();
  const handleError = useHandleErrorHook();

  const [encodingOptions, setEncodingOptions] = useState<Array<SelectOption>>([]);
  useEffect(() => {
    axios.get<{ encodings: Array<SelectOption> }>(`${apiPrefix}/helpers/encodings`, { cancelToken: source.token })
      .then(({ data }) => setEncodingOptions(data.encodings))
      .catch(handleError);

    return () => source.cancel();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    encodingOptions
  }
}

type SubmitPayload = {
  encoding: string;
  matchingCodeColumn: string;
  file: File;
  dataset: string;
}

export const useUploadHook = () => {
  const [submitPending, setPending] = useState<boolean>(false);
  const handleError = useHandleErrorHook();
  const setMessage = useSetMessage();
  const intl = useIntl();

  const submit = async ({ encoding, matchingCodeColumn, file, dataset }: SubmitPayload, callback: () => void) => {
    setPending(true);

    const body = new FormData();
    body.append('files', file);
    body.append('encoding', encoding);
    body.append('matching_code_column', matchingCodeColumn);
    body.append('dataset', dataset);

    const response = await axios.post<FormData, AxiosResponse<ResponseWithMessages>>(`${apiPrefix}/import`, body)
      .catch(handleError);

    setPending(false);

    if (response && response?.data) {
      const { success, messages } = response.data;

      if (success) {
        callback();
      }

      return setMessage({
        type: success ? 'info' : 'error',
        content: success
          ? intl.formatMessage({ id: "tabs.upload.messages.success" })
          : (messages.length
            ? messages
            : intl.formatMessage({ id: "app.messages.error.unexpected" })),
      });
    }

    return setMessage({
      type: 'error',
      content: intl.formatMessage({ id: "app.messages.error.unexpected" }),
    });
  }

  return {
    submit,
    submitPending,
  }
}
