import React, { FocusEvent, useEffect, useState } from 'react';
import { merge } from '@rossum/rossum-ui/helpers';
import { Select } from '@rossum/rossum-ui/Select';
import { FormattedMessage } from 'react-intl';
import styles from '../styles.module.sass';
import TrashIcon from 'mdi-react/TrashIcon';
import CheckIcon from 'mdi-react/CheckIcon';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggableProvided,
} from 'react-beautiful-dnd';
import { MatchingRule, SelectOption } from '../../../types';
import InfoTooltip from '../../../components/InfoTooltip';
import './styles.sass';
import { Input } from '@rossum/rossum-ui/Input';
import { inRange, take, takeRight, uniqueId } from 'lodash';
import { minThreshold } from '../../../config';
import FormErrorMessage from '../../../components/Form/FormErrorMessage';

type Props = {
  masterDataKeys: Array<SelectOption>;
  matchableFields: Array<SelectOption>;
  matchingRules: Array<MatchingRule>;
  matchingTechniques: Array<SelectOption>;
  setMatchingRules: React.Dispatch<React.SetStateAction<MatchingRule[]>>;
  submitPending: boolean;
  desyncedRules: Array<string>;
  thresholdErrorsState: [
    Record<string, boolean>,
    React.Dispatch<React.SetStateAction<Record<string, boolean>>>,
  ];
};

const reorder = (
  list: Array<MatchingRule>,
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const MatchingLogicRules = ({
  masterDataKeys,
  matchableFields,
  matchingTechniques,
  matchingRules,
  setMatchingRules,
  submitPending,
  thresholdErrorsState: [thresholdErrors, setThresholdErrors],
  desyncedRules,
}: Props) => {
  const [matchingTechnique, setMatchingTechnique] = useState<string>(
    matchingTechniques[0]?.value,
  );

  useEffect(() => {
    setMatchingTechnique(matchingTechniques[0]?.value);
  }, [matchingTechniques]);

  useEffect(() => {
    setField(matchableFields[0]?.value);
  }, [matchableFields]);

  useEffect(() => {
    setMasterDataKey(masterDataKeys[0]?.value);
  }, [masterDataKeys]);

  const [targetField, setField] = useState<string>('');
  const [masterDataKey, setMasterDataKey] = useState<string>('');

  const addingDisabled =
    submitPending ||
    matchableFields.length === 0 ||
    masterDataKeys.length === 0;

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const reorderedRules = reorder(
      matchingRules,
      result.source.index,
      result.destination.index,
    );

    setMatchingRules(reorderedRules);
  };

  const validateThreshold = ({
    value,
    id,
  }: {
    value: string;
    id: string;
  }): void => {
    const valueAsNumber = Number(value);

    const isValidNumber =
      isFinite(valueAsNumber) &&
      (inRange(valueAsNumber, minThreshold, 1) || valueAsNumber === 1);
    const isEmpty = value === '';

    const hasError = !(isValidNumber || isEmpty);

    return setThresholdErrors((errors) => ({
      ...errors,
      [id]: hasError,
    }));
  };

  return (
    <div className={styles.MatchingTable}>
      <div className={styles.MatchingTableRow}>
        <div className={styles.MatchingTableHeadCell} />
        <div className={styles.MatchingTableHeadCell}>
          <div className={styles.HeaderWithTooltip}>
            <FormattedMessage id="tabs.logic.rules.masterDataColumn" />
            <InfoTooltip translationId="tabs.logic.rules.masterDataColumn.tooltip" />
          </div>
        </div>
        <div className={styles.MatchingTableHeadCell}>
          <div className={styles.HeaderWithTooltip}>
            <FormattedMessage id="tabs.logic.rules.targetField" />
            <InfoTooltip translationId="tabs.logic.rules.targetField.tooltip" />
          </div>
        </div>
        <div className={styles.MatchingTableHeadCell}>
          <div className={styles.HeaderWithTooltip}>
            <FormattedMessage id="tabs.logic.rules.matchingTechnique" />
            <InfoTooltip translationId="tabs.logic.rules.matchingTechnique.tooltip" />
          </div>
        </div>
        <div className={styles.MatchingTableHeadCell}>
          <div className={styles.HeaderWithTooltip}>
            <FormattedMessage id="tabs.logic.rules.threshold" />
            <InfoTooltip translationId="tabs.logic.rules.threshold.tooltip" />
          </div>
        </div>
        <div className={styles.MatchingTableHeadCell} />
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided, listSnapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {!!matchingTechniques.length &&
                matchingRules.map(
                  (
                    {
                      targetField,
                      matchingTechnique: _matchingTechnique,
                      masterDataKeys: _masterDataKeys,
                      threshold = '',
                      id,
                    },
                    index,
                  ) => {
                    const ruleId = `matchingRule-${targetField}-${_masterDataKeys.join(
                      '-',
                    )}-${index}`;
                    const head = take(matchingRules, index);
                    const tail = takeRight(
                      matchingRules,
                      matchingRules.length - index - 1,
                    );

                    const onThresholdBlur = (
                      event: FocusEvent<HTMLInputElement>,
                    ) => {
                      const value = event.target.value;

                      return validateThreshold({
                        value,
                        id,
                      });
                    };

                    const onThresholdChange = (value: string) => {
                      const inputTouched =
                        Object.keys(thresholdErrors).includes(id);

                      if (inputTouched) {
                        validateThreshold({ value, id });
                      }

                      setMatchingRules((rules) => [
                        ...head,
                        { ...rules[index], threshold: value },
                        ...tail,
                      ]);
                    };

                    return (
                      <Draggable
                        draggableId={ruleId}
                        index={index}
                        key={ruleId}
                      >
                        {(provided: DraggableProvided) => (
                          <div
                            className={merge(
                              styles.MatchingTableRow,
                              styles.RuleRow,
                              submitPending && styles.RuleRowDisabled,
                              listSnapshot.isDraggingOver &&
                                styles.RuleRowDraggingActive,
                            )}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <div className={styles.MatchingTableBodyCell}>
                              <div
                                className={styles.DragHandler}
                                {...provided.dragHandleProps}
                              >
                                <div className={styles.DragHandlerInnerLeft} />
                                <div className={styles.DragHandlerInnerRight} />
                              </div>
                            </div>
                            <div className={styles.MatchingTableBodyCell}>
                              <div className={styles.CellValue}>
                                {_masterDataKeys.join(', ')}
                                {_masterDataKeys.some((key) =>
                                  desyncedRules.includes(key),
                                ) && (
                                  <div className={styles.ErrorMasterData}>
                                    <FormErrorMessage>
                                      <FormattedMessage id="tabs.logic.rules.desyncedMasterData" />
                                    </FormErrorMessage>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className={styles.MatchingTableBodyCell}>
                              <div className={styles.CellValue}>
                                {
                                  matchableFields.find(
                                    ({ value }) => value === targetField,
                                  )?.label
                                }
                              </div>
                            </div>
                            <div className={styles.MatchingTableBodyCell}>
                              <div className={styles.CellValue}>
                                {
                                  matchingTechniques.find(
                                    ({ value }) => value === _matchingTechnique,
                                  )?.label
                                }
                              </div>
                            </div>
                            <div className={styles.MatchingTableBodyCell}>
                              {_matchingTechnique === 'fuzzy' ? (
                                <div className={styles.CellInput}>
                                  <Input
                                    className={styles.ThresholdInput}
                                    onBlur={onThresholdBlur}
                                    value={threshold}
                                    placeholder={`${minThreshold}`}
                                    onChange={onThresholdChange}
                                    hasError={thresholdErrors[id]}
                                  />
                                  {thresholdErrors[id] && (
                                    <div
                                      className={
                                        styles.ThresholdErrorPlaceholder
                                      }
                                    >
                                      <span className={styles.ThresholdError}>
                                        <FormattedMessage id="tabs.logic.rules.threshold.invalidValue" />
                                      </span>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div className={styles.CellValue}>
                                  <span className={styles.TextMuted}>
                                    <FormattedMessage id="tabs.logic.rules.threshold.notAvailable" />
                                  </span>
                                </div>
                              )}
                            </div>
                            <div className={styles.MatchingTableBodyCell}>
                              <button
                                className={merge(
                                  styles.Button,
                                  styles.RemoveButton,
                                )}
                                onClick={() => {
                                  setMatchingRules([...head, ...tail]);
                                  setThresholdErrors(
                                    ({ [id]: _deleted, ...errors }) => errors,
                                  );
                                }}
                              >
                                <TrashIcon />
                              </button>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  },
                )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div
        className={merge(
          styles.MatchingTableRow,
          addingDisabled && styles.AddRowDisabled,
        )}
      >
        <div className={styles.AddRowCell} />
        <div className={styles.AddRowCell}>
          <Select
            key={masterDataKeys.join('')}
            title="masterDataKeySelect"
            options={masterDataKeys}
            value={masterDataKey}
            placeholder="— — —"
            onChange={setMasterDataKey}
            isDisabled={addingDisabled}
          />
        </div>
        <div className={styles.AddRowCell}>
          <Select
            key={matchingRules.join('')}
            title="targetFieldSelect"
            options={matchableFields}
            value={targetField}
            placeholder="— — —"
            onChange={setField}
            isDisabled={addingDisabled}
          />
        </div>

        <div className={styles.AddRowCell}>
          <Select
            title="matchingTechniqueSelect"
            options={matchingTechniques}
            value={matchingTechnique}
            placeholder="— — —"
            onChange={setMatchingTechnique}
            isDisabled={addingDisabled}
          />
        </div>
        <div className={styles.AddRowCell}>
          <button
            disabled={addingDisabled}
            className={merge(styles.Button, styles.AddButton)}
            onClick={() =>
              setMatchingRules((rules) => [
                ...rules,
                {
                  targetField,
                  matchingTechnique,
                  masterDataKeys: [masterDataKey],
                  compound_match: false,
                  joining_char: ', ',
                  id: uniqueId(),
                },
              ])
            }
          >
            <CheckIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default MatchingLogicRules;
