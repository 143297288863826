import { uniqueId } from 'lodash';
import { MatchingRule, Rules } from "../../types";
import parser from './matchingFlowParser';

export const makeRulesFromConfiguration = (rules: Rules): Array<MatchingRule> => rules.map(({
  compound_match,
  joining_char,
  threshold,
  ...rule
}) => ({
  matchingTechnique: rule.fuzzy ? 'fuzzy' : 'exact',
  targetField: rule.field_to_match,
  masterDataKeys: rule.matching_keys,
  compound_match,
  joining_char,
  threshold: `${threshold}`,
  id: uniqueId(),
}));


export type MatchingFlowOperator = 'AND' | 'OR';
export type MatchingFlowRule = {
  rule: number,
  // null is used for the last rule in a group
  nextOperator: MatchingFlowOperator | null
};

export type ParsedMatchingFlow = {
  group: MatchingFlowRule[],
  // null is used for the last group in matching flow
  nextOperator: MatchingFlowOperator | null
}[];

export const parseMatchingFlow = (query: string): ParsedMatchingFlow => {
  const trimmedQuery = query.trim();

  if (trimmedQuery === '') return [];

  return parser.parse(trimmedQuery) as ParsedMatchingFlow;
};

const updatedAt = <T,>(arr: T[], index: number, updateFn: (value: T) => T) => {
  return arr.map((value, i) => i === index ? updateFn(value) : value);
}

/*
 * All operations that can be done with ParsedMatchingFlow are defined here.
 * They should preserve the invariants:
 * - nextOperator is null only for the last rule in a group, or for the last row.
 */
export const removeRow = (matchingFlow: ParsedMatchingFlow, rowIndex: number): ParsedMatchingFlow => {
  const result = matchingFlow.filter((_, i) => i !== rowIndex);

  if (result.length === 0) {
    return result;
  }

  // Last operator has to be null
  return [...result.slice(0, -1), { ...result[result.length - 1], nextOperator: null }];
};

export const appendRow = (matchingFlow: ParsedMatchingFlow, operator: MatchingFlowOperator, rule: number): ParsedMatchingFlow => {
  if (matchingFlow.length === 0) {
    return [{group: [{rule, nextOperator: null}], nextOperator: null}];
  }

  return [
    ...matchingFlow.slice(0, -1),
    { group: matchingFlow[matchingFlow.length - 1].group, nextOperator: operator},
    { group: [{ rule, nextOperator: null }], nextOperator: null }
  ];
};

export const appendRule = (matchingFlow: ParsedMatchingFlow, rowIndex: number, operator: MatchingFlowOperator, rule: number): ParsedMatchingFlow => {
  const group = matchingFlow[rowIndex].group;

  if (group.length === 0) {
    return updatedAt(matchingFlow, rowIndex, row => ({ ...row, group: [{rule, nextOperator: null}] }));
  }

  return updatedAt(matchingFlow, rowIndex, row => ({
    ...row,
    group: [
      ...row.group.slice(0, -1),
      { rule: row.group[row.group.length - 1].rule, nextOperator: operator },
      { rule, nextOperator: null }
    ]}));
};

export const setNextOperatorForRule = (matchingFlow: ParsedMatchingFlow, rowIndex: number, index: number, operator: MatchingFlowOperator): ParsedMatchingFlow =>
  updatedAt(matchingFlow, rowIndex, row => ({
    ...row,
    group: updatedAt(row.group, index, value => ({ ...value, nextOperator: operator }))
  }));

export const setNextOperatorForRow = (matchingFlow: ParsedMatchingFlow, rowIndex: number, operator: MatchingFlowOperator): ParsedMatchingFlow =>
  updatedAt(matchingFlow, rowIndex, row => ({
    ...row,
    nextOperator: operator
  }));
