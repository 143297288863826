import axios, { AxiosError } from 'axios';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import {
  getDataMatchingQueues,
  getRossumQueues,
  dataMatchingQueuesQueryKey,
  rossumQueuesQueryKey,
} from '../../lib/api';
import {
  DataMatchingQueue,
  RossumQueue,
  ResponseWithMessages,
  isDataMatchingQueue,
  Schema,
  SelectOption,
  SectionChild,
  MultivalueTable,
  SchemaSection
} from '../../types';

export const useQueuesQuery = () => {
  const {
    data: dataMatchingQueuesData,
    error: dataMatchingQueuesError,
    isLoading: dataMatchingQueuesLoading,
  } = useQuery<{ queues: DataMatchingQueue[] }, AxiosError<ResponseWithMessages>>(
    dataMatchingQueuesQueryKey,
    getDataMatchingQueues,
  );

  const {
    data: rossumQueuesData,
    error: rossumQueuesError,
    isLoading: rossumQueuesLoading,
  } = useQuery<{ results: RossumQueue[] }, AxiosError<ResponseWithMessages>>(
    rossumQueuesQueryKey,
    () => getRossumQueues({ page_size: 100 }),
    // fetch Rossum queues when Data Matching queues returns an empty array - { queues: [] }
    { enabled: dataMatchingQueuesData?.queues.length === 0 }
  );

  const queues = useMemo(() => {
    const queuesArray = (dataMatchingQueuesData?.queues.length
      ? dataMatchingQueuesData.queues
      : rossumQueuesData?.results) || []

    return queuesArray.map(
      (queue) => ({
        id: queue.id,
        value: String(queue.id),
        label: queue.name,
        schema: queue.schema,
        matching_config: isDataMatchingQueue(queue) ? queue.matching_config : null,
      }),
    );
  }, [dataMatchingQueuesData, rossumQueuesData]);

  return {
    queues,
    isLoading: dataMatchingQueuesLoading || rossumQueuesLoading,
    error: dataMatchingQueuesError || rossumQueuesError,
  };
};

export const isMultivalueTable = (datapoint: SectionChild): datapoint is MultivalueTable =>
  datapoint.category === "multivalue" && datapoint.children.category === "tuple";

const getOptionsFromNode = (node: SchemaSection['children'], parentLabel: string = ''): SelectOption[] =>
  ([] as SelectOption[]).concat(...node.map(nodeChild =>
    [
      {
        value: nodeChild.id,
        label: `${nodeChild.label}${parentLabel ? ` (in ${parentLabel})` : ''}`
      },
      ...(isMultivalueTable(nodeChild)
        ? getOptionsFromNode(nodeChild.children.children, nodeChild.label)
        : []
      )
    ]
  ));

export const getOptionsFromSchema = (schema: Schema): SelectOption[] =>
  ([] as SelectOption[]).concat(...schema.content.map(section => getOptionsFromNode(section.children)));

export const useQueueSchema = (schemaUrl: string | undefined) => {
  const { data, status } = useQuery(['schemas', schemaUrl],
    () => axios.get<Schema>(schemaUrl as string).then(r => r.data),
    {
      enabled: !!schemaUrl,
      select: getOptionsFromSchema,
    }
  );

  return { schema: data, status }
}
