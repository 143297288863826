import axios from "axios";

declare global {
  interface Window {
    Rollbar?: { configure: Function, error: Function };
    rollbarEnabled: string;
    rollbarEnvironment: string;
    rossumUrl: string;
    initSmartlook?: () => void;
    smartlook?: (event: string, value: string | number, payload?: { [key: string]: string }) => void;
  }
}

export const rossumUrl =
  `${window.rossumUrl === '{{rossum_url}}' ? process.env.REACT_APP_BE_API_URL || '' : window.rossumUrl}/v1`;

export const rollbarEnabled = window.rollbarEnabled?.toLowerCase() === 'true';

export const rollbarEnvironment =
  window.rollbarEnvironment === '{{rollbar_environment}}'
    ? 'unknown'
    : window.rollbarEnvironment;

const servedOnSvc = window.location.pathname.includes('/svc/');
export const basename = servedOnSvc
  // /svc/data-matching-legacy/route => /svc/data-matching-legacy
  ? window.location.pathname.split('/').filter(s => !!s).slice(0, 2).join('/')
  : undefined;

export const apiPrefix = '/api/v1';

const baseUrl = `${window.location.origin}/${basename}`;

axios.defaults.baseURL = servedOnSvc ? baseUrl : undefined;

export const sampleFilePath = `${servedOnSvc ? baseUrl : ''}${apiPrefix}`

export const minThreshold = 0.3;

export const standardColumnsArticle = 'https://rossum.ai/help/article/supported-data-types-master-data-matching/';

export const defaultMatchingLabel = "Company Match";

export const fileLimit = 50;

export const DEV_FEATURES_ENABLED =
  window.localStorage.getItem('devFeaturesEnabled') || false;
