import { createContext, useEffect, useState } from "react";
import { MessageT } from './types';

import { useLocation } from 'react-router-dom';

type MessageState = MessageT | null;

const defaultMessage: MessageState = null;

export const messageTimeout = 6000;

export const messagesContext = createContext({
  message: defaultMessage as MessageState,
  setMessage: (() => { }) as React.Dispatch<React.SetStateAction<MessageState>>
});

export const useProvideMessages = () => {
  const [message, setMessage] = useState<MessageState>(defaultMessage);
  const location = useLocation();

  useEffect(() => {
    if (message) {
      setMessage(null);
    }
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    message,
    setMessage
  };
};
