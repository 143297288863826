import { getName } from "../lib/helpers";
import { User } from "../types";

export const initSmartlook = (user: User) => {
  window.initSmartlook?.();
  window.smartlook?.('consentAPI', 'I consent');
  window.smartlook?.('consentIP', 'I consent');
  window.smartlook?.('identify', user.id, {
    name: getName(user),
    email: user.email,
  });
}
