import React, { ReactNode } from 'react';
import FileIcon from 'mdi-react/FileOutlineIcon';
import Box from '../../../components/Box';
import styles from '../styles.module.sass';
import { FormattedMessage } from 'react-intl';
import { apiPrefix, sampleFilePath } from '../../../config';
import InfoOutlineIcon from 'mdi-react/InfoOutlineIcon';

type Props = {
  title: ReactNode;
  description?: ReactNode;
  samples?: Array<string>;
  id: string;
  isInfoBox?: boolean;
  readMoreLink?: string;
};

const SampleBox = ({
  isInfoBox,
  readMoreLink,
  id,
  samples,
  title,
  description,
}: Props) => (
  <Box className={styles.SampleBox}>
    <div className={styles.SampleInfo}>
      {isInfoBox ? <InfoOutlineIcon size={26} /> : <FileIcon size={26} />}
      <div>
        <div className={styles.Title}>{title}</div>
        {description && <div className={styles.Description}>{description}</div>}
      </div>
    </div>
    <div className={styles.Formats}>
      {readMoreLink && (
        <a
          className={styles.SampleFormat}
          href={readMoreLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id={`tabs.upload.guide.readMore`} />
        </a>
      )}
      {samples &&
        samples.map((sample) => (
          <a
            key={`${sample}-${id}-format`}
            className={styles.SampleFormat}
            href={`${sampleFilePath}/helpers/sample_files/${id}.${sample}`}
            download
          >
            <FormattedMessage id={`tabs.upload.formats.${sample}`} />
          </a>
        ))}
    </div>
  </Box>
);

export default SampleBox;
