import React from 'react';
import { IntlProvider } from 'react-intl';
import locales from './locales';
import { AuthProvider } from './AuthProvider';
import Routes from './Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { MessagesProvider } from './MessagesProvider';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { basename } from './config';

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <IntlProvider messages={locales} locale="en" defaultLocale="en">
        <Router basename={basename}>
          <MessagesProvider>
            <AuthProvider>
              <Routes />
            </AuthProvider>
          </MessagesProvider>
        </Router>
      </IntlProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};

export default App;
