import { compact } from "lodash";
import isPlainObject from "lodash/isPlainObject";
import { Dataset, DatasetOption, RossumQueue, SelectOption, User, Workspace } from "../types";

type ConverterFn = (input: string) => string;

type InputObject = { [key: string]: string | Array<string> | number | boolean | InputObject }

export const convertKeysInObject =
  <T = InputObject>(converter: ConverterFn) =>
    (inputObject: InputObject): T =>
      Object.keys(inputObject).reduce((acc, key) => {
        const value = inputObject[key];
        return ({
          ...acc,
          [converter(key)]: isPlainObject(value) ? convertKeysInObject(converter)(value as InputObject) : value
        });
      },
        {} as T);

export const getName = ({ firstName, lastName, username, email } = {} as User) =>
  firstName || lastName ? `${firstName} ${lastName}`.trim() : username || email;

export const makeOption = (value: string | number) => ({ label: value, value } as SelectOption)

export const getWorkspacesNameMap = (workspaces: Array<Workspace>) => workspaces.reduce((acc, _workspace) => {
  return { ...acc, [_workspace.url]: _workspace.name }
}, {} as Record<string, string>);

export const appendWorkspaceNameToQueue = (queues: Array<RossumQueue>, workspaceMap: Record<string, string>) =>
  queues.map(queue => ({
    ...queue,
    name: compact([workspaceMap[queue.workspace], queue.name]).join(': ')
  }));

export const convertDatasetsToOptions = ({ datasets }: { datasets: Dataset[] }): DatasetOption[] => {
  return datasets.map(
    (dataset) =>
    ({
      ...makeOption(dataset.dataset),
      ...dataset,
      master_data_keys: dataset.master_data_keys.map(makeOption),
    } as DatasetOption),
  );
}
