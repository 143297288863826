import { merge } from '@rossum/rossum-ui/helpers';
import React, { ReactNode } from 'react';
import styles from './styles.module.sass';

type Props = {
  children: ReactNode;
  align?: 'center';
};

const FormErrorMessage = ({ children, align }: Props) => (
  <div
    className={merge(styles.ErrorMessage, align && styles[`Align-${align}`])}
  >
    {children}
  </div>
);

export default FormErrorMessage;
