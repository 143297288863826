import { useEffect, useState } from "react";
import { Hook, RossumQueue, ResponseWithMessages, Workspace } from "../../types";
import { authGet, useHandleErrorHook } from "../../lib/api";
import axios, { AxiosResponse } from 'axios';
import { apiPrefix } from "../../config";
import { useSetMessage } from "../../MessagesProvider";
import { useIntl } from "react-intl";
import { appendWorkspaceNameToQueue, getWorkspacesNameMap } from "../../lib/helpers";

const isDataMatchingHook = (hook?: Hook) => hook?.config.url?.includes('data-matching');

export const useRemoveTabDataHook = () => {
  const source = axios.CancelToken.source();
  const handleError = useHandleErrorHook();

  const [queues, setQueues] = useState<Array<RossumQueue>>([]);
  const [dataMatchingHooks, setHooks] = useState<Array<Hook>>([]);
  useEffect(() => {
    authGet<{ hooks: Array<Hook>, results: Array<RossumQueue>, workspaces: Array<Workspace> }>(
      '/queues?&page_size=100&sideload=hooks,workspaces',
      { cancelToken: source.token }
    ).then(({ data: { results, hooks, workspaces } }) => {
      const _dataMatchingHooks = hooks.filter(isDataMatchingHook);
      const workspaceNameByUrl = getWorkspacesNameMap(workspaces);
      const queuesWithHook = results.filter(queue =>
        queue.hooks.some(hookUrl =>
          _dataMatchingHooks.find(hook => hook.url === hookUrl)))
      const queuesWithWorkspaceName = appendWorkspaceNameToQueue(queuesWithHook, workspaceNameByUrl);

      setQueues(queuesWithWorkspaceName);

      setHooks(_dataMatchingHooks);
    })
      .catch(handleError);

    return () => source.cancel();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    queues,
    setQueues,
    dataMatchingHooks,
  }
}

type SubmitPayload = Array<{ hook_id: number; queue_id: number }>;

export const useRemoveWebhooksHook = () => {
  const [submitPending, setPending] = useState<boolean>(false);
  const handleError = useHandleErrorHook();
  const setMessage = useSetMessage();
  const intl = useIntl();

  const submit = async (
    payload: SubmitPayload,
    onSuccess: () => void,
    onError: () => void
  ) => {
    setPending(true);

    const response = await axios.delete<{ hooks: SubmitPayload }, AxiosResponse<ResponseWithMessages>>(
      `${apiPrefix}/hooks`,
      { data: { hooks: payload } }
    )
      .catch(handleError);

    setPending(false);

    if (response && response?.data) {
      const { success, messages } = response.data;

      setMessage({
        type: success ? 'info' : 'error',
        content: success ? intl.formatMessage({ id: "tabs.remove.messages.success" }, { count: messages.length }) : messages,
      })

      return success ? onSuccess() : onError();
    }

    return onError();
  }

  return {
    submit,
    submitPending,
  }
}
