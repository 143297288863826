import React from 'react';
import ReactDOM from 'react-dom';
import './index.sass';
import App from './App';
import {
  setTheme,
  setBorderRadiuses,
  setPrimaryColors,
} from '@rossum/rossum-ui/theme';
import { initScripts } from './lib/initScripts';

setTheme('dark');
setPrimaryColors();
setBorderRadiuses();
initScripts();

ReactDOM.render(<App />, document.getElementById('root'));
