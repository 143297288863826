import { apiPrefix, minThreshold, rossumUrl } from '../config';
import axios, { AxiosError } from 'axios';
import { SECURE_TOKEN } from './localStorage';
import { useAuth } from '../AuthProvider';
import {
  DataMatchingQueue,
  Dataset,
  MatchingSpecsResponse,
  RossumQueue,
  ResponseWithMessages,
  MatchingRule,
  Rules,
  ReferenceRelationT
} from '../types';

const getToken = (token = localStorage.getItem(SECURE_TOKEN)) =>
  `Token ${token}`;

axios.interceptors.request.use(config => {
  return (config.url?.startsWith(apiPrefix) || config.url?.startsWith(rossumUrl)) ?
    {
      ...config,
      headers: {
        Authorization: getToken(),
        ...config.headers,
      },
    } : config;
}, (error) => {
  return Promise.reject(error);
});

export const authGet = <T>(path: string, options?: {}) =>
  axios.get<T>(
    path.startsWith('http') ? path : `${rossumUrl}${path}`,
    options
  );

export const useHandleErrorHook = () => {
  const { logout } = useAuth();

  return (error: AxiosError<ResponseWithMessages>) => {
    if (axios.isCancel(error)) {
      return;
    }

    if (window.Rollbar) {
      const status = error?.response?.status || 'Unknown status';
      const method = error?.response?.config?.method || 'Unknown method';
      const requestUrl = error?.response?.config?.url || 'Unknown request URL'
      const responseBody = error?.response?.data || 'No body'

      const title = `${status} ${method.toUpperCase()} ${requestUrl}`;

      const payload = {
        title,
        fingerprint: title,
        responseBody,
      }

      window.Rollbar.configure({ payload });
      window.Rollbar.error(error, { ...error });
    }

    if (error.response) {
      if (error.response.status === 401) {
        return logout({ sessionExpired: true });
      }

      return error.response;
    }

    return console.error(error);
  }
}

export const dataMatchingQueuesQueryKey = 'dataMatchingQueues';
export const getDataMatchingQueues = () =>
  axios
    .get<{ queues: Array<DataMatchingQueue> }>(`${apiPrefix}/helpers/queues`)
    .then(r => r.data);

export const rossumQueuesQueryKey = 'rossumQueues';
export const getRossumQueues = (params: Object) =>
  authGet<{ results: Array<RossumQueue> }>('/queues', { params })
    .then(r => r.data);

export const datasetsQueryKey = 'datasets';
export const getDatasets = () =>
  axios
    .get<{ datasets: Array<Dataset> }>(`${apiPrefix}/datasets`)
    .then(r => r.data);

export const matchingSpecsQuery = 'matchingSpecsQuery';
export const getMatchingSpecs = (queueId: number) => () =>
  axios
    .get<MatchingSpecsResponse>(`${apiPrefix}/helpers/matching_specs?queue_id=${queueId}`)
    .then(r => r.data);

type MatchingPayload = {
  queue_id: number,
  dataset: string,
  matching_enum: string,
  matching_field_label: string,
  extra_identifier: string | null,
  reference_relation: ReferenceRelationT,
  reference_field: string,
}

export const createMatchingConfig = (matchingConfigPayload: MatchingPayload) =>
  axios
    .post<ResponseWithMessages>(
      `${apiPrefix}/match/config`,
      matchingConfigPayload
    );

type MatchingLogicPayload = {
  rules: Array<MatchingRule>;
  limitation: string;
  queue_id: number;
  dataset: string;
};

type MatchingLogicBody = {
  queue_id: number;
  matching_rules: [{
    rules: Rules;
    dataset: string;
    message_type: string;
    id: number,
  }]
}

export const createMatchingLogic = ({
  limitation,
  rules,
  queue_id,
  dataset,
}: MatchingLogicPayload) => {
  const body: MatchingLogicBody = {
    queue_id,
    matching_rules: [{
      dataset,
      id: 1,
      message_type: limitation,
      rules: rules.map(({ targetField, matchingTechnique, masterDataKeys, threshold, ...rest }, index: number) => {
        return ({
          field_to_match: targetField,
          fuzzy: matchingTechnique === "fuzzy",
          matching_keys: masterDataKeys,
          threshold: Number(threshold || minThreshold),
          ...rest,
          id: index + 1,
        })
      }),
    }]
  }

  return axios
    .post<ResponseWithMessages & { hook_id: number }>(
      `${apiPrefix}/match/logic`,
      body
    )
};
