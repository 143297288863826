import { AppBackground } from '@rossum/rossum-ui/AppBackground';
import { Tabs, Tab } from '@rossum/rossum-ui/Tabs';
import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  RouteProps,
} from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import CloudIcon from 'mdi-react/CloudUploadIcon';
import CardText from 'mdi-react/CardTextIcon';
import ExitIcon from 'mdi-react/ExitToAppIcon';
import ImageFilterCenterFocus from 'mdi-react/ImageFilterCenterFocusIcon';
import TrashIcon from 'mdi-react/TrashIcon';
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.sass';
import Header from '../components/Header';
import { Logo } from '@rossum/rossum-ui/Logo';
import Upload from '../tabs/Upload';
import MatchingLogic from '../tabs/MatchingLogic';
import Remove from '../tabs/Remove';
import LoginPage from '../LoginPage';
import { LOGGED_IN_WITH_TOKEN } from '../lib/localStorage';
import PromoContainer from '../components/PromoContainer';
import { useTracking } from '../Tracking/hooks';
import SetupMatchingField from '../tabs/SetupMatchingField';

const uploadCompaniesRoute = '/upload-companies';
const setupMatchingFieldRoute = '/setup-matching-field';
export const matchingLogicRoute = '/matching-logic';
const removeExtensionRoute = '/remove-extension';

const AppTabs = () => {
  const history = useHistory();
  const location = useLocation();
  const { logout } = useAuth();
  const loggedInWithToken =
    localStorage.getItem(LOGGED_IN_WITH_TOKEN) === 'true';

  return (
    <Tabs>
      <Tab
        active={location.pathname === uploadCompaniesRoute}
        onClick={() => history.push(uploadCompaniesRoute)}
      >
        <CloudIcon />
        <FormattedMessage id="tabs.upload.title" />
      </Tab>

      <Tab
        active={location.pathname === setupMatchingFieldRoute}
        onClick={() => history.push(setupMatchingFieldRoute)}
      >
        <CardText />
        <FormattedMessage id="tabs.setup.title" />
      </Tab>

      <Tab
        active={location.pathname === matchingLogicRoute}
        onClick={() => history.push(matchingLogicRoute)}
      >
        <ImageFilterCenterFocus />
        <FormattedMessage id="tabs.logic.title" />
      </Tab>

      <Tab
        active={location.pathname === removeExtensionRoute}
        onClick={() => history.push(removeExtensionRoute)}
      >
        <TrashIcon />
        <FormattedMessage id="tabs.remove.title" />
      </Tab>

      <div className={styles.LogoutLink}>
        <Tab
          onClick={() => logout({ shouldCloseWindow: loggedInWithToken })}
          active={false}
        >
          <ExitIcon />
          <FormattedMessage
            id={loggedInWithToken ? 'app.goBackToRossum' : 'app.logout'}
          />
        </Tab>
      </div>
    </Tabs>
  );
};

const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  let { isLoggedIn } = useAuth();
  useTracking(isLoggedIn);

  return (
    <Route
      {...rest}
      render={() =>
        isLoggedIn ? (
          <div className={styles.Container}>
            <Header />
            <PromoContainer />
            <AppTabs />
            {children}
          </div>
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

const PendingScreen = () => (
  <div className={styles.PendingScreen}>
    <Logo />
    <div>
      <FormattedMessage id="app.pending" />
    </div>
  </div>
);

const Routes = () => {
  const { authPending } = useAuth();

  return (
    <AppBackground>
      {authPending ? (
        <PendingScreen />
      ) : (
        <Switch>
          <PrivateRoute path={uploadCompaniesRoute}>
            <Upload />
          </PrivateRoute>
          <PrivateRoute path={setupMatchingFieldRoute}>
            <SetupMatchingField />
          </PrivateRoute>
          <PrivateRoute path={matchingLogicRoute}>
            <MatchingLogic />
          </PrivateRoute>
          <PrivateRoute path={removeExtensionRoute}>
            <Remove />
          </PrivateRoute>

          <Route exact path="/">
            <LoginPage />
          </Route>

          <Redirect to="/" />
        </Switch>
      )}
    </AppBackground>
  );
};

export default Routes;
